// overrides Material styling so that dropdown text is centered vertically
.MuiSelect-root {
  display: flex;
}
.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.08);
}
// overrides tab indicator color
.MuiTabs-indicator {
  background-color: $accent;
}
// override tab scroll button
.MuiTabScrollButton-root {
  flex: 0 0 2rem;
}
// makes inactive tabs opacity 100%
.MuiTab-textColorInherit {
  opacity: 1;
}
// .MuiTab-root {
//   min-width: 6rem;
// }
// makes checked radio button color blue
.MuiRadio-colorSecondary.MuiRadio-checked {
  color: $icon-color;
}
// makes circular progress animation color blue
.MuiCircularProgress-colorPrimary {
  color: $icon-color;
}
.MuiButtonBase-root.MuiButtonBase-disabled {
  cursor: not-allowed;
}
.MuiButton-root {
  font-weight: 400;
}
.MuiTooltip-tooltip {
  background-color: $japanese-indigo;
  font-size: 14px;
  font-family: 'Rubik';
}
.MuiButton-label {
  width: inherit;
}
// styling for popover paper (menus, and dialogs)
// TODO: Review how we can set separate overrides depending on whether the user is in sampark or sambandh
.MuiPopover-paper {
  border: 1px solid $columbia-grey;
  color: $japanese-indigo;
  border-radius: 8px;
  -webkit-box-shadow: 0px 2px 10px 0px rgba(42, 61, 76, 0.14);
  -moz-box-shadow: 0px 2px 10px 0px rgba(42, 61, 76, 0.14);
  box-shadow: 0px 2px 10px 0px rgba(42, 61, 76, 0.14);
}
.MuiPaper-elevation16 {
  -webkit-box-shadow: 0px 2px 10px 0px rgba(42, 61, 76, 0.25);
  -moz-box-shadow: 0px 2px 10px 0px rgba(42, 61, 76, 0.25);
  box-shadow: 0px 2px 10px 0px rgba(42, 61, 76, 0.25);
}
.MuiMenuItem-root {
  color: $japanese-indigo;
  font-family: 'Rubik', sans-serif;
  text-transform: capitalize;
}
.MuiMenuItem-root:hover {
  background-color: rgba(195, 210, 223, 0.4);
}
.MuiMenuItem-root.MuiMenuItem-selected {
  background-color: rgba(195, 210, 223, 0.4);
}
.MuiSelect-select {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.MuiSelect-select:focus {
  background-color: transparent;
}
.MuiChip-deletable:focus {
  background-color: white;
}
.MuiList-root:focus {
  outline: none;
}
.MuiListItem-divider {
  border-bottom: 1px solid $off-body;
}
.MuiAvatar-root {
  font-family: 'Rubik', sans-serif;
}
.MuiTypography-root {
  font-family: 'Rubik';
}
.MuiTypography-body1 {
  color: $japanese-indigo;
  font-family: 'Rubik', sans-serif;
}
.MuiSelect-select.MuiSelect-disabled {
  cursor: not-allowed;
}
.MuiTableCell-head {
  font-size: 14px;
  color: $japanese-indigo;
}
.MuiTableCell-root {
  font-family: 'Rubik', 'sans-serif';
}
.MuiTableCell-root {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid $columbia-grey;
}
.MuiTableCell-body {
  text-transform: capitalize;
}
