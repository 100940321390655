.container {
  display: flex;
  flex-direction: column;
}

.container span {
  display: block;
  margin: 0.5rem auto 1rem;
}

.preview-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;
  border: 1px solid #444;
  border-radius: 9px;
  padding: 8px;
  min-height: 200px;
  margin-bottom: 16px;
}

.preview-container p {
  color: hsl(0, 0%, 30%);
  align-self: center;
  text-align: center;
  width: 100%;
}

.image-wrapper {
  width: 150px;
  height: auto;
}

.preview-image {
  width: 150px;
  height: auto;
  object-fit: cover;
  border-radius: 4px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.image-wrapper {
  position: relative;
  cursor: move;
}

.image-wrapper button {
  display: none;
  position: absolute;
  top: 5px;
  right: 5px;
}

.image-wrapper button span {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.image-wrapper button:after {
  content: '\00d7';
}

.image-wrapper:hover button {
  display: block;
}

.controls {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  position: relative;
  align-items: center;
  margin: 0 auto;
  width: fit-content;
}

.controls input#id_image_upload {
  opacity: 0;
  position: absolute;
  width: 0px;
}

.controls label,
.controls button {
  cursor: pointer;
  appearance: none;
  font-size: 1.125rem;
  line-height: 1.85rem;
  border-radius: 24px;
  font-weight: 800;
  background-color: hsl(0, 0%, 95%);
  border: none;
  padding: 0.5rem 1rem;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  transition: box-shadow 150ms ease-in-out;
}

.controls label:hover,
.controls button:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.controls label {
  background-color: #c77256;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.controls button.clear-button {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  height: 34px;
  position: absolute;
  right: -40px;
}

.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
  padding: 2px 0 8px;
}
