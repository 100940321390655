@import '../../../styles/utils/palette.scss';

.search {
    margin: 16px 24px 0 24px;
}

.listButton {
  display: block;
  width: 100%;
  padding: 0;

 &:hover {
   background-color: $cloudy;
 }
}

.iconButton {
    margin-left: 24px;
}

.editButton {
    display: block;
    width: 100%;
    padding: 0;

    &:hover {
        background-color: transparent;
    }
}

.selectedListItem {
    background-color: $cloudy;
    border-radius: 0;
}

.message {
  padding: 1.5rem;
  text-align: center;
}