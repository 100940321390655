@import '../utils/palette.scss';

.container {
  position: relative;
}

.containerOpen {
  position: fixed;
  z-index: 1;
}

.suggestionsList {
  position: absolute;
  z-index: 100;
  background-color: white;
  border: 1px solid $off-body;
  border-top: none;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  list-style-type: none;
  padding: 0;
  -webkit-box-shadow: 0px 2px 10px 0px rgba(42, 61, 76, 0.14);
  -moz-box-shadow: 0px 2px 10px 0px rgba(42, 61, 76, 0.14);
  box-shadow: 0px 2px 10px 0px rgba(42, 61, 76, 0.14);
  margin: 0;
  overflow-y: scroll;
  max-height: 200px;
}

.suggestionHighlighted {
  background-color: rgba(0, 0, 0, 0.04);
}
