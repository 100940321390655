.event-list-card-container {
  background: $white;
  border: 1px solid $columbia-grey;
  border-radius: 4px;
  min-height: 80px;
  align-content: center;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1.5rem;

  .rsvp-value {
    text-align: center;
  }

  .label {
    font-weight: 500;
  }

  .event-card-long-content {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 25rem;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .event-list-left {
    display: flex;
    align-items: center;

    .event-checkbox-container {
      .checkbox {
        cursor: pointer;
        position: absolute;
        top: 29px;
        left: 16px;
      }
    }

    .event-avatar-container {
      border-radius: 3px;
      border: solid 3px #c3d2df;
      height: 64px;
      width: auto;
      margin-right: 1rem;

      .event-list-card-image {
        height: 64px;
        width: auto;
      }
    }

    .event-name-location-container {
      margin-right: 4rem;
    }

    .event-date-container {
      margin-right: 4rem;
    }

    .event-createdBy-container {
      margin-right: 4rem;

      .createdBy-value {
        text-transform: capitalize;
      }
    }
  }

  .event-button-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .event-manage-button {
      border: 2px solid $ocean-blue;
      font-family: 'Rubik', sans-serif;
      border-radius: 3px;
      color: $ocean-blue;
      font-size: 14px;
      text-transform: capitalize;
      width: 6rem;
      max-height: 55px;
      justify-self: end;
      margin-right: 15px;

      &:hover {
        background-color: $ocean-blue;
        border-radius: 3px;
        font-family: 'Rubik', sans-serif;
        color: $white;
        font-size: 14px;
        text-transform: capitalize;
        max-width: 150px;
        max-height: 55px;
        justify-self: end;
      }
    }
  }
}

// iPhone 6, 7, 8
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) {
  .events-view-container {
    .events-data-container {
      .events-cards-list-view {
        .event-list-card-container {
          display: block;
          padding: 1rem;

          .event-list-left {
            display: block;

            .event-name-location-container {
              .event-card-long-content {
                overflow: auto;
                white-space: normal;
                width: auto;
              }
            }
          }

          .event-button-container {
            display: flex;
            justify-content: left;
            margin-top: 1rem;
          }
        }
      }
    }
  }
}

// iPhone 5
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
  .events-view-container {
    .events-data-container {
      .events-cards-list-view {
        .event-list-card-container {
          display: block;
          padding: 1rem;

          .event-list-left {
            display: block;

            .event-name-location-container {
              .event-card-long-content {
                overflow: auto;
                white-space: normal;
                width: auto;
              }
            }
          }

          .event-button-container {
            display: flex;
            justify-content: left;
            margin-top: 1rem;
          }
        }
      }
    }
  }
}
