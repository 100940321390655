.professional-info-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
    'job-mentorship job-mentorship'
    'company-name job-title'
    'job-specifications job-specifications';

    .field-job-mentorship {
        grid-area: job-mentorship;
    }
    .field-company-name {
        grid-area: company-name;
    }
    .field-job-title {
        grid-area: job-title;
    }
    .field-job-specifications {
        grid-area: job-specifications;
    }
}