/* 
 * PADDING
 * 1 rem = 16px
 */

.padding {
	padding: 2rem;
}

.padding-left {
	padding-left: 2rem;
}

.padding-right {
	padding-right: 2rem;
}

.padding-bottom {
	padding-bottom: 2rem;
}

.padding-top {
	padding-top: 2rem;
}

.padding-sm {
	padding: 1rem;
}

.padding-sm-bottom {
	padding-bottom: 1rem;
}

.padding-sm-top {
	padding-top: 1rem;
}

.padding-sm-left {
	padding-left: 1rem;
}
.padding-xsm-left {
	padding-left: 0.5rem;
}
.padding-xsm-right {
	padding-right: 0.5rem;
}
.padding-xsm-bottom {
	padding-bottom: 0.5rem;
}

.padding-sm-right {
	padding-right: 1rem;
}
