.manage-data-card-container {
  background: $white;
  position: relative;
  border: 1px solid $columbia-grey;
  border-radius: 4px;
  -webkit-box-shadow: 0px 2px 10px 0px rgba(42,61,76,0.14);
  -moz-box-shadow: 0px 2px 10px 0px rgba(42,61,76,0.14);
  box-shadow: 0px 2px 10px 0px rgba(42,61,76,0.14);
  padding: 32px 0px;

  .manage-data-card-header {
    .manage-data-card-title {
      cursor: default;
      font-size: 18px;
      font-weight: 500;
      margin: 0 0 0 24px;
      text-transform: uppercase;
    }
  }

  .manage-data-items {
    margin-top: 10px;

    .manage-data-list-item {
      display: grid;
      grid-template-columns: 13% 63% 20%;
      grid-gap: 1%;
      align-items: center;
      padding: 18px 0px;
      margin: 0px 24px;
      border-bottom: 1px solid $columbia-grey;
      text-transform: capitalize;

      .manage-data-location-state {
        text-transform: uppercase;
      }
    }

    .manage-data-approval-list-item {
      display: grid;
      grid-template-columns: 13% 63% 20%;
      grid-gap: 1%;
      align-items: center;
      padding: 18px 0px;
      margin: 0px 24px;
      border-bottom: 1px solid $columbia-grey;
      text-transform: capitalize;
      .manage-data-avatar-name-location {
        display: grid;
        grid-template-areas: 'name status' 'location status';
        grid-template-columns: 50%;

        .manage-data-name {
          grid-area: name;
        }

        .manage-data-location {
          grid-area: location;

          .manage-data-location-state {
            text-transform: uppercase;
          }
        }

        .manage-data-user-status {
          grid-area: status;
        }
      }
    }

    .manage-data-avatar {
      background-color: $white;
      color: $columbia-grey;
      border: 3px solid $columbia-grey;
      font-size: 18px !important;
      height: 44px;
      width: 44px;
      justify-self: center;
      text-transform: uppercase;
    }

    .manage-data-name {
      font-weight: 500;
    }

    .manage-data-user-status {
      border-radius: 3px;
      cursor: default;
      font-size: 12px;
      max-height: 24px;
      max-width: 64px;
      text-align: center;
      text-transform: capitalize;
      align-self: center;
      padding: 5px 0px;

      &.invited {
        background-color: $columbia-grey;
        color: $japanese-indigo;
      }

      &.pending {
        background-color: $sunglow-yellow;
        color: $japanese-indigo;
      }

      &.verified {
        background-color: $ocean-blue;
        color: $white;
      }

      &.declined {
        background-color: $reject-red;
        color: $white;
      }
    }

    .manage-data-button {
      border: 3px solid $ocean-blue;
      font-family: 'Rubik', sans-serif;
      border-radius: 3px;
      color: $ocean-blue;
      font-size: 14px;
      text-transform: capitalize;
      max-width: 150px;
      max-height: 55px;
      justify-self: end;
    }

    .manage-data-button:hover {
      background-color: $ocean-blue;
      border-radius: 3px;
      font-family: 'Rubik', sans-serif;
      color: $white;
      font-size: 14px;
      text-transform: capitalize;
      max-width: 150px;
      max-height: 55px;
      justify-self: end;
    }

    .disable-button {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }
}

.MuiModal-root {
  .contact-form-dismiss {
    top: 0px;
    position: absolute;
    right: 0;
    transform: translate(-100%,100%);

    &:hover {
      cursor: pointer;
    }
  }
}


// iPhone 6, 7, 8
@media only screen
and (min-device-width : 375px)
and (max-device-width : 736px) {
  .manage-data-card-container {
    padding: 24px 0px;
    margin: 48px 0;

    .manage-data-items {
      .manage-data-list-item {
        display: grid;
        grid-template-columns: 14% 60% 20%;
        margin: 0px 16px;
      }

      .manage-data-approval-list-item {
        grid-template-columns: 14% 60% 20%;
        margin: 0px 12px;
        div {
          font-size: 12px;
        }
      }

      .manage-data-avatar {
        font-size: 16px !important;
        height: 40px;
        width: 40px;
      }

      .manage-data-button {
        font-size: 12px;
        min-width: 84px;
        padding: 10px 8px;
      }

      .manage-data-button:hover {
        font-size: 12px;
        min-width: 80px;
        padding: 10px 8px;
      }
    }
  }
}
