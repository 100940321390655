.error-message-container {
  width: 30rem;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: center;
  height: 90vh;
  align-items: center;
  margin: 0 auto;

  .error-message-image {
    max-width: 10rem;
    max-height: 10rem;
  }

  .error-message-title {
    font-size: 32px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .error-message-content {
    font-size: 18px;
    text-align: center;
    margin-bottom: 0.5rem;
  }

  .error-message-submessage {
    font-size: 18px;
    text-align: center;
  }
}

div.sampark-logo {
  padding: 2rem 0 0 2rem;
}

