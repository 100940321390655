.date-time-fields {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 1rem;

  .date-fields {

  }

  .time-fields {

  }
}