.events-view-container {
  display: grid;
  grid-template-areas:
    'events-header'
    'events-data-container';

  .events-header {
    margin: 0 0 24px 0px;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .events-header-row1 {
      display: flex;
      justify-content: space-between;

      .events-title {
        color: $japanese-indigo;
        margin: auto 0px;
        font-size: 24px;
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    .events-tabs {
      margin-top: 5px;
    }
  }

  .events-data-container {
    padding: 0 4.5rem;

    .events-cards-list-view {
      display: grid;
      grid-row-gap: 0.5rem;
    }
  }
}

// iPhone 6, 7, 8
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) {
  .events-view-container {
    .events-header {
      padding: 0px 2.5rem;
    }

    .events-data-container {
      padding: 0px 2.5rem;
    }
  }
}

// iPhone 5
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
  .events-view-container {
    .events-header {
      padding: 0px 2rem;

      .events-header-row1 {
        .events-title {
          font-size: 18px;
        }
      }

      .events-tabs {
        display: flex;
        justify-content: center;
      }
    }

    .events-data-container {
      padding: 0px 1.5rem;
    }
  }
}
