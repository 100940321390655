.contact-us-container {
    width: 30rem;
    border-radius: 2px;
    box-shadow: 0 2px 10px 0 rgba(42, 61, 76, 0.08);
    background-color: #ffffff;
    border: solid 1px $off-body;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    justify-self: center;
    height: fit-content;

    .contact-us-title {
        font-size: 20px;
        font-weight: 500;
        text-align: left;
    }

    .contact-us-message {
        font-size: 14px;
        text-align: left;
    }

    .button-container {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        .contact-us-button {
            font-family: 'Rubik';
            height: 3rem;
            width: 7rem;
            border-radius: 2px;
            text-transform: capitalize;
        }

        .contact-us-button.button-disabled {
            background-color: $off-body;
            color: $secondary-accent;
            cursor: not-allowed;
        }

        .button-active {
            background-color: $accent;
            color: $white;
        }

        .button-active:hover {
            background-color: darken($accent, 20%);
        }

        .cancel-button {
            color: $icon-color;
        }
    }
}
