$sunset-orange: #ff5a52;
$ocean-blue: #067bc2;
$columbia-grey: #c3d2df;
$japanese-indigo: #2a3d4c;
$moonstone-grey: #5c85a7;
$crimson-red: #d63230;
$sunglow-yellow: #ffca3a;
$lime-green: #8ac926;
$white: #ffffff;
$nickel: #f9fafb;
$smoke-grey: #f4f5f6;
$tangerine: #de8e05;
$slate-grey: #899eb0;
$light-grey: #dde6ef;
$denim-blue: #2256bf;
$tag-blue: #f0f4fc;
$charcoal: #4e5d6a;
$gold: #9e7726;
$vanilla: #fff3d2;
$indigo: #2f1b72;
$lavender: #efebfb;
$cloudy: #ebf0f5;

$text-body: $japanese-indigo;
$off-body: $columbia-grey;
$accent: $sunset-orange;
$secondary-accent: $moonstone-grey;
$icon-color: $ocean-blue;
$approve-green: #2ec4b6;
$reject-red: #e0294f;

// old!
$coral: #ff5840;
$pale-grey: #f6f7f8;
$turquoise-blue: #11bbdf;
$greyish-brown: #4a4a4a;
$reddish-orange: #f75d1c;
$duck-egg-blue: #b6eefa;
$water-blue: #11a2c2;
$disabled: #bbbbbb;
