.nav-panel {
  height: inherit;
  padding: 1rem 1rem;
  min-height: 100vh;

  .top-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .nav-panel-buttons {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: calc(100% - 80px);
  }

  .nav-panel-tabs {
    display: flex;
    flex-direction: column;

    .navLink {
      margin-bottom: 1.5rem;
      font-size: 18px;
    }

    .activeNavLink {
      color: $sunset-orange;
    }
  }

  .bottom-section {
    // position: absolute;
    // bottom: 2rem;

    .panel-buttons {
      margin-bottom: 1.5rem;
      font-size: 18px;
    }
  }
}
