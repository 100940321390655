h3 {
  font-weight: 500;
}

.event-gallery-event-images {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  img {
    max-width: 200px;
    border: 2px solid $columbia-grey;
    border-radius: 4px;
    align-self: center;
  }
}

.events-image-uploader {
  .fileContainer {
    border: 2px dashed $columbia-grey;
  }
}

.event-gallery-actions {
  button:disabled {
    background-color: $columbia-grey;
  }
}
