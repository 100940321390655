.filter-panel {
  .filter-panel-header {
    border: 1px solid var(--Gray-20, #e8ebf4);
    margin-bottom: 16px;
    background-color: white;
    z-index: 1;
    position: sticky;
    top: 0;
  }
  .section-header {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 16px;
  }
  .filter-panel-top-row {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    position: relative;

    .title-text {
      font-size: 22px;
      margin-left: 8px;
      font-weight: 500;
    }

    .filter-form-dismiss {
      cursor: pointer;
      position: absolute;
      bottom: 5px;
      right: 0px;
    }
  }

  .filter-form-container {
    padding: 0 2rem;
  }

  .filter-section {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--Gray-20, #e8ebf4);

    .filter-container {
      //
    }
    .inline {
      display: flex;

      label,
      .filterField {
        flex: 1;
      }
    }

    h5 {
      border-bottom: 1px solid #c3d2df;
      font-size: 14px;
      margin: 0px;
      padding-bottom: 8px;
    }

    .gender-clear-link,
    .emailType-clear-link,
    .audiences-clear-link,
    .mandal-clear-link,
    .tag-clear-link {
      position: absolute;
      right: 10px;
      top: 0px;
    }

    .select-field {
      margin-top: 1rem;
      width: 98%;
    }
  }
}
