.contacts-view-container {
  .contact-info-drawer {
    width: 576px;
    min-width: 576px;
  }

  .contacts-data-container-list {
    min-width: 790px;
  }
  .contacts-header {
    margin: 0 0 24px 0px;
    padding: 0rem 24px;

    .contacts-title-new-contact {
      display: flex;
      justify-content: space-between;
      padding-top: 12px;
      padding-bottom: 0px;
      @media only screen and (min-width: 600px) {
        padding-bottom: 12px;
      }
    }

    .header-bottom-border {
      padding-bottom: 1rem;
      border-bottom: 1px solid $columbia-grey;
    }

    .contacts-title {
      color: $japanese-indigo;
      margin: auto 1rem auto 0px;
      font-size: 24px;
      font-weight: 500;
      .contacts-title-number {
        font-size: small;
        font-weight: lighter;
        color: grey;
      }
    }

    .contact-sort-button,
    .filter-button,
    .contact-export-button {
      margin-right: 20px;
      padding: 5px 0px 0px 0px;
      @media only screen and (max-width: 600px) {
        margin-right: 0;
      }
    }

    .contact-view-button {
      margin-right: 20px;
      padding: 8px 0px 0px 0px;
    }

    .add-new-contact-button {
      background-color: $accent;
      border-radius: 2px;
      height: 32px;
      width: 100px;
    }

    .contact-header-utility-buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      // margin-top: 1rem;
      @media only screen and (max-width: 600px) {
        flex-direction: column;
        font-size: medium;
        gap: 10px;
      }

      .contact-header-search-container {
        display: flex;
        align-items: center;
        @media only screen and (max-width: 600px) {
          flex-direction: row;
          flex-wrap: wrap;
        }
        .contact-search-icon-container {
          position: relative;
          margin-right: 1rem;
          @media only screen and (max-width: 600px) {
            width: 100%;
            margin-right: 0;
          }
        }

        .contact-filter-search {
          border: 1px solid #c3d2df;
          border-radius: 3px;
          color: #848eac;
          height: 40px;
          padding: 10px 35px 10px 45px;
          font-size: 16px;
          outline: none;
          width: 325px;
          @media only screen and (max-width: 600px) {
            width: 100%;
            border-bottom-right-radius: 0px;
            border-bottom-left-radius: 0px;
          }
          &.active {
            color: $japanese-indigo;
          }

          &:focus {
            border-color: $ocean-blue;
            background-color: white;
            box-shadow: 0 0 4px 0 rgba(6, 123, 194, 0.5);
          }
        }

        .icon-search {
          cursor: pointer;
          position: absolute;
          top: 8px;
          left: 12px;
        }

        .icon-close {
          cursor: pointer;
          position: absolute;
          right: 16px;
          top: 13px;
        }

        .contact-filter-state-input-container {
          margin-bottom: 0px;
          margin-right: 1rem;
          @media only screen and (max-width: 600px) {
            position: relative;
            margin-right: 0;
            flex: 1;
            transform: scaleY(1);
            transition: height 2s;
            border-top: none;
          }
        }

        .contact-filter-state-input {
          border: 1px solid #c3d2df;
          border-radius: 3px;
          color: #848eac;
          height: 40px;
          font-size: 16px;
          width: 150px;
          @media only screen and (max-width: 600px) {
            flex: 1;
            width: 100%;
            border-top: none;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-right: 0px;
          }
        }
        .contact-filter-city-input-container {
          position: relative;
          flex: 1;
        }

        .contact-filter-city-input {
          border: 1px solid #c3d2df;
          border-radius: 3px;
          color: #848eac;
          height: 40px;
          padding: 10px 35px 10px 10px;
          font-size: 16px;
          outline: none;
          width: 200px;
          @media only screen and (max-width: 600px) {
            flex: 1;
            width: 100%;
            transform: scaleY(1);
            transition: transform 10s;
            border-top: 0px;
            border-bottom-left-radius: 0px;
            border-top-right-radius: 0px;
          }
          &.active {
            color: $japanese-indigo;
          }
          .icon-close {
            cursor: pointer;
            position: absolute;
            right: 16px;
            top: 42px;
          }

          &:focus {
            border-color: $ocean-blue;
            background-color: white;
            box-shadow: 0 0 4px 0 rgba(6, 123, 194, 0.5);
          }
        }
      }

      .contact-header-utility-buttons {
        .contact-header-utility-button {
          display: flex;
          flex-direction: column;
          align-items: center;
          @media only screen and (max-width: 600px) {
            display: flex;
            align-items: baseline;
          }
        }
      }
    }

    .contacts-filters-container {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .contact-header-utility-button {
    @media only screen and (max-width: 600px) {
      display: flex;
      align-items: baseline;
    }
  }
  @keyframes slideDown {
    from {
      top: 19rem;
    }
    to {
      top: 22rem;
    }
  }

  @keyframes slideUp {
    from {
      top: 22rem;
    }
    to {
      top: 19rem;
    }
  }

  .contacts-data-container {
    margin: 0 auto;
    overflow-x: scroll;

    .contact-list-headers {
      padding-bottom: 1rem;
      @media only screen and (min-width: 1020px) {
        padding: 0 1rem 1rem 1rem;
      }

      .left-group {
        margin-right: 116px;
      }

      .basic-info {
        margin-left: 90px;
      }

      .basic-info,
      .contact-details,
      .follow-up,
      .tags {
        width: 20%;
      }

      .nickname,
      .app {
        width: 15%;
      }

      .app {
        text-align: center;
      }

      .sort-arrow-indicator {
        margin-left: 0.5rem;
        height: 7px;
        margin-bottom: 1px;
      }

      .active-sort {
        color: $ocean-blue;
        font-weight: 500;
      }

      .left-group {
        display: flex;
        align-items: center;
        flex-grow: 1;
        padding-left: 1rem;
        font-weight: 500;

        .checkbox {
          width: 54px;
        }
        .contact-card-first-name,
        .contact-card-last-name,
        .contact-card-city {
          width: 7rem;
          margin-right: 2rem;
          font-weight: 500;
        }
        .contact-card-state {
          width: 3.5rem;
          margin-right: 2rem;
          font-weight: 500;
        }
        .contact-card-list-nick-name {
          width: 5.1rem;
          margin-right: 2rem;
          font-weight: 500;
        }
        .contact-card-email {
          width: 12rem;
          margin-right: 1.5rem;
          font-weight: 500;
        }
        .contact-card-phoneNumber {
          width: 7rem;
          font-weight: 500;
        }
      }

      .contact-card-actions {
        display: flex;
        align-items: center;
      }
    }
    .contacts-data-card-list-container {
      overflow-y: scroll;
      box-sizing: border-box;
      margin: 0 auto;
      @media only screen and (min-width: 1020px) {
        padding: 0 1rem;
      }

      &.contacts-list-view {
        height: calc(100vh - 265px);
      }

      &.contacts-data-lowered {
        // top: 22rem;
        animation-name: slideDown;
        animation-duration: 0.25s;
      }

      &.contacts-data-leveled {
        // top: 15rem;
        animation-name: slideUp;
        animation-duration: 0.25s;
      }

      .contact-cards-card-view {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(16rem, 16rem));
        grid-column-gap: 2rem;
        grid-row-gap: 2rem;
        justify-content: center;
        @media only screen and (max-width: 600px) {
          height: 40rem;
        }
      }

      .lazy-loading-spinner {
        margin: 30px auto auto auto;
      }

      .load-more-button-container {
        display: flex;
        justify-content: center;
        margin: 24px 0px;
      }

      .load-more-button-extra-space {
        margin-bottom: 85px;
      }
    }
  }

  .contacts-data-message {
    font-size: 18px;
    text-align: center;
    padding-top: 100px;
  }

  .contacts-selected-footer {
    background-color: #ffffff;
    box-shadow: 10px 10px 8px 13px #e3e3e4;
    cursor: default;
    bottom: 0;
    top: auto;

    .contacts-selected-count {
      position: absolute;
      .selected-count {
        background-color: #ff583f;
        border-radius: 10px;
        color: #ffffff !important;
        padding: 2px 10px;
        margin-right: 5px;
      }

      span {
        color: $japanese-indigo;
        text-transform: uppercase;
        vertical-align: middle;
        margin-left: 8px;
      }

      .checkbox-disabled {
        cursor: not-allowed;
        opacity: 0.5;
        pointer-events: none;
      }
    }

    .contacts-selected-actions {
      margin: 0 auto;

      .contacts-action {
        cursor: pointer;
        display: inline-block;

        &:first-of-type {
          margin-right: 32px;
        }

        div {
          display: inline-block;
        }

        i {
          font-size: 18px;
        }

        span {
          text-transform: uppercase;
        }
      }
    }

    .contacts-selected-warning {
      color: $sunset-orange;
      position: absolute;
      text-transform: uppercase;
      margin-right: 1.5rem;
      right: 0;
    }
  }
}
// Small laptop screen
@media only screen and (max-width: 1280px) and (min-width: 737px) {
  .contacts-view-container {
    .contacts-data-container {
      padding: 0;
      overflow-x: scroll;

      .contact-list-headers {
        padding: 0 1rem;
      }
      .contacts-data-card-list-container {
        padding: 0 1rem;
      }
    }
    .contacts-header {
      padding: 0 2rem;
    }
  }
}

// iPhone 6, 7, 8
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) {
  .contacts-view-container {
    .contacts-header {
      padding: 0.5rem 2rem;
      margin: 2.5rem 0;
      background: #fafafa;
      position: sticky;
      top: 0;
      z-index: 1;

      .contacts-title-button-container {
        display: block;

        .contacts-title {
          font-size: 18px;
        }

        .contact-header-utility-buttons-container {
          position: relative;

          button {
            position: relative;
            top: 30px;
          }

          .button-primary {
            position: absolute;
            top: -35px;
            right: 0;
          }
        }
      }
    }
  }
}

// iPhone 5
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
  .contacts-view-container {
    .contacts-header {
      padding: 0.5rem 1rem;
      margin: 0.5rem 0;
      background: #fafafa;
      position: sticky;
      top: 0;
      z-index: 1;
      .contacts-title-button-container {
        display: block;

        .contacts-title {
          font-size: 18px;
        }

        .contact-header-utility-buttons-container {
          position: relative;

          button {
            position: relative;
            top: 30px;
          }

          .button-primary {
            position: absolute;
            top: -35px;
            right: 0;
          }
        }
      }
    }
  }
}
