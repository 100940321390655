.signup-container {
    width: 36rem;
    border-radius: 2px;
    box-shadow: 0 2px 10px 0 rgba(42, 61, 76, 0.08);
    background-color: #ffffff;
    border: solid 1px $off-body;
    padding: 4rem 2rem 2rem 2rem;
    justify-self: center;
    display: flex;
    flex-direction: column;
    height: fit-content;
    position: relative;

    .field-avatar-uploader {
        margin-top: -9rem;
        margin-bottom: 2rem;
        height: 8rem;
        width: 8rem;
        margin-right: auto;
        margin-left: auto;
    }

    .signup-form-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .signup-form-fields {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto;
            grid-column-gap: 1rem;
            grid-template-areas:
            'field-first-name field-last-name'
            'field-email field-phone'
            'field-address-1 field-address-2'
            'field-address-city field-address-state-zip';
    
            .field-email {
                grid-area: field-email;
            }
    
            .field-phone {
                grid-area: field-phone;
            }
    
            .field-first-name {
                grid-area: field-first-name;
            }
    
            .field-last-name {
                grid-area: field-last-name;
            }
            .field-address-1 {
                grid-area: field-address-1;
            }
            .field-address-2 {
                grid-area: field-address-2;
            }
            .field-address-city {
                grid-area: field-address-city;
            }
            .field-address-state-zip {
                grid-area: field-address-state-zip;
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 1rem;
            }
            .bottom-text {
                text-align: center;
            }

            input:focus {
                outline: none;
            }

            .signup-form-label {
                margin-bottom: 1rem;
            }

            .signup-gender {
                text-align: left;
            }
        }

        .signup-form-button {
            font-family: 'Rubik';
            width: 100%;
            max-width: 11rem;
            height: 3rem;
            border-radius: 2px;
            text-transform: capitalize;
            margin: 1rem 0;
        }

        .button-active {
            background-color: $accent;
            color: $white;
        }

        .button-active:hover {
            background-color: darken($accent, 20%);
        }

        .button-disabled {
            background-color: $off-body;
            color: $secondary-accent;
            cursor: not-allowed;
        }
    }
}

.signup-infobox {
    width: 36rem;
    padding: 1rem 1.5rem;
    background-color: #e8f3fc;
    color: $secondary-accent;
    border-radius: 2px;
    border: 1px solid $off-body;
}
