@import '../../../styles/utils/palette.scss';

.tags-popover {
  width: 275px;
  .category {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.25rem;
    color: $charcoal;
    margin: 0.5rem 0;
  }

  .tag {
    font-size: 1rem;
    padding: 0.5rem;

    display: inline-block;
    margin: 0.25rem 0;
    margin-left: 5px;
  }

  .blue {
    color: $denim-blue;
    background-color: $tag-blue;
  }

  .gold {
    color: $gold;
    background-color: $vanilla;
  }

  .lavender {
    color: $indigo;
    background-color: $lavender;
  }

  .grey {
    color: $japanese-indigo;
    background-color: $columbia-grey;
  }
}

.tags {
  .tag-count {
    color: $denim-blue;
    padding: 0.5rem 1rem;
    display: inline-block;
    background-color: $tag-blue;
  }
}
