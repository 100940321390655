.content-form {
  
  // display: grid;
  // grid-template-areas:
  // 'content';

  // .field-content {
  //   grid-area: content;
  // }

  // .field-title {
  //   grid-area: title;
  // }

  .fields-reference {
    display: flex;

    .field-ref-location {
      flex-grow: 2;
      padding-right: 1rem;
    }

    .field-ref-date {
      flex-grow: 1;
    }
  }

  // .field-ref-location {
  //   grid-area: ref-location;
  // }

  // .field-ref-date {
  //   grid-area: ref-date;
  // }

  // .field-publish-date {
  //   grid-area: pub-date;
  // }
}