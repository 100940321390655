.levelTwoSelect {
    max-width: 10rem;
    margin-right: 0.5rem;
}

.flexed-container {
    display: flex;
}

.level-two-fields-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
}

.message-with-bullet {
    &:before {
        content: '*'
    }
}