.loading-panel {
  height: 100%;
  padding: 2rem;
  .loading-panel-top-section {
    text-align: right;

    .loading-form-dismiss {
      cursor: pointer;
    }
  }
}