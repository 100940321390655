@import './utils/index.scss';
@import './layouts/index.scss';
@import './views/index.scss';
@import './components/index.scss';
@import './overrides/index.scss';

.sampark-body {
  background-color: #f9fafb;
}

button:focus {
  outline: none;
}

hr {
  height: 2px;
  border: 1px solid $off-body;
}

.disabled-input {
  opacity: 0.6;
  cursor: not-allowed;
  background-color: $smoke-grey;
}

a {
  text-decoration: none;
  color: $ocean-blue;
}
input:focus {
  outline: none;
}
textarea:focus {
  outline: none;
}
.flex-container {
  // display: flex;
  // justify-content: center;
  // align-content: center;
  // align-items: center;
  display: grid;
  grid-template-rows: auto;
}

.flex-column {
  flex-direction: column;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $columbia-grey;
  font-family: 'Rubik', sans-serif;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: $columbia-grey;
  font-family: 'Rubik', sans-serif;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: $columbia-grey;
  font-family: 'Rubik', sans-serif;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: $columbia-grey;
  font-family: 'Rubik', sans-serif;
}
// For delete dialog
.contacts-selected-dialog {
  .MuiPaper-root {
    padding: 1.5rem;
  }

  .MuiTypography-title {
    font-weight: 500;
    font-family: 'Rubik';
  }

  p {
    color: $text-body;
    font-family: 'Rubik';
    font-size: 14px;
  }

  .cancel-button {
    color: $icon-color;
    text-transform: capitalize;
  }

  .confirm-button {
    background-color: $accent;
    color: $white;
    text-transform: capitalize;
  }
}

.lazy-loading-spinner {
  text-align: center;
}

.custom-box-shadow {
  -webkit-box-shadow: 0px 2px 10px 0px rgba(42, 61, 76, 0.14);
  -moz-box-shadow: 0px 2px 10px 0px rgba(42, 61, 76, 0.14);
  box-shadow: 0px 2px 10px 0px rgba(42, 61, 76, 0.14);
}

.react-autosuggest__suggestions-list {
  position: absolute;
  z-index: 2;
  background-color: white;
  border: 1px solid $off-body;
  border-top: none;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  list-style-type: none;
  padding: 0;
  padding-bottom: 16px;
  box-shadow: 0px 2px 10px 0px rgba(42, 61, 76, 0.14);
  margin: 0 0 16px;
  overflow-y: scroll;
  max-height: 200px;
}

.pac-container {
  z-index: 10000 !important;
}
