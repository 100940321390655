@import '../../../styles/utils/palette.scss';

.ui-list-item-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  &:hover {
    background-color: $cloudy;
  }
  &:after {
    content: '';
    border-bottom: 1px solid $cloudy;
    width: 96%;
    margin: 0 auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  } 
}

.ui-list-item {
  display: grid;
  grid-template-columns: 88% 0% 10%;
  grid-gap: 1%;
  padding: 16px 0 16px 24px;
  text-transform: capitalize;
    
}
.approval-list{
  .ui-list-item {
  grid-template-columns: 75% 0% 10%;
  }
}  
.listItemContainer {
  display: block;
  width: 100%;
  padding: 0;

 &:hover {
   background-color: transparent;
 }
}

.ui-list-item--action-button {
  margin-right: 24px;
  max-width: 300px;
}

.ui-list-item--icon-contaier {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 300px;
}

.ui-list-item--control-badge {
  background-color: $columbia-grey;
  color: $japanese-indigo;
  border-radius: 36px;
  padding: 6px 10px;
  font-size: 14px;
  line-height: 14px;
  display: block;
}

.ui-list-item--alert-icon {
  cursor: pointer;
}

.ui-list-item--details-container {
  display: grid;
  grid-template-areas: 'name status' 'location status';
  grid-template-columns: 50%;
}

.ui-list-item--tag {
  border-radius: 3px;
  cursor: default;
  font-size: 12px;
  max-height: 24px;
  max-width: 64px;
  min-width: 52px;
  text-align: center;
  text-transform: capitalize;
  align-self: center;
  padding: 1px 0px;
  &.invited {
    background-color: $columbia-grey;
    color: $japanese-indigo;
  }

  &.pending {
    background-color: $sunglow-yellow;
    color: $japanese-indigo;
  }

  &.verified {
    background-color: $ocean-blue;
    color: $white;
  }

  &.declined {
    background-color: $reject-red;
    color: $white;
  }
}

.approval-list{
  .ui-list-item--tag{
  max-width: 80px;
  min-width: 75px;  
  }
}