.data-list-card-container {
  background: $white;
  position: relative;
  border: 1px solid $columbia-grey;
  border-radius: 4px;
  -webkit-box-shadow: 0px 2px 10px 0px rgba(42,61,76,0.14);
  -moz-box-shadow: 0px 2px 10px 0px rgba(42,61,76,0.14);
  box-shadow: 0px 2px 10px 0px rgba(42,61,76,0.14);
  padding: 1.5rem 1.5rem;

  .data-list-card-header {

    .data-list-card-title {
      cursor: default;
      font-size: 18px;
      font-weight: 500;
      text-transform: uppercase;
    }

    margin-bottom: 1rem;
  }

  .data-list-items {
    .status {
      border-radius: 3px;
      cursor: default;
      font-size: 12px;
      max-height: 24px;
      min-width: 5rem;
      text-align: center;
      text-transform: capitalize;
      align-self: center;
      padding: 5px 8px;

      &.pending {
        background-color: $sunglow-yellow;
        color: $japanese-indigo;
      }

      &.declined {
        background-color: $reject-red;
        color: $white;
      }
      &.cancelled {
        background-color: $reject-red;
        color: $white;
      }

      &.approved {
        color: $white;
        background-color: $approve-green;
      }

      &.expired {
        background-color: $smoke-grey;
        color: $moonstone-grey;
      }

      &.sent {
        background-color: $ocean-blue;
        color: $white;
      }
    }

    .status::first-letter {
      text-transform: uppercase;
    }

    .button-more-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

  }
}
