.welcome-container {

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        .signin-button {
            border: 2px solid #e3e3e4;
            border-radius: 2px;
            margin: 1rem auto;
            width: 80%;
            height: 50px;

            img {
              max-width: 140%;
            }
        }
    }

    .loading-spinner {
        color: $reddish-orange !important;
    }
}

.landing-page {
    height: inherit;
    display: flex;
    flex-direction: row;

    .landing-page-left {
        width: 60%;
        background-color: $accent;
        position: relative;

        .landing-page-logo {
            filter: brightness(0) invert(1);
            margin: auto;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
        }

        .landing-page-men {
            margin: auto;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
        }
    }

    .landing-page-right {
        width: 40%;
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;

        .landing-page-sign-in {
            display: flex;
            flex-direction: column;
            text-align: center;
            grid-row: 2/3;
            justify-content: center;

            .signin-button {
                border: 2px solid #e3e3e4;
                border-radius: 2px;
                margin: 0.5rem auto;
                width: 50%;
                height: 50px;

                img {
                    max-width: 140%;
                }
            }

            .button-disabled {
                filter: saturate(0) opacity(0.6);
                background-color: #e3e3e4;
                cursor: not-allowed;
            }
        }

        .landing-page-disclaimer {
            grid-row: 3/4;
            align-self: end;
            padding: 2.5rem 2rem;
            line-height: 1.71;
        }
    }

    .loading-spinner {
        color: $reddish-orange !important;
    }
}

@media (min-width: 600px) {

    .landing-page-logo-responsive {
        display: none;
    }

}

@media (max-width: 600px) {

    .landing-page-left {
        display: none;
    }

    .landing-page-right {
        width: 100% !important;
    }

    .landing-page-logo-responsive {
        display: block;
        margin: auto;
    }

}