.contact-us-fields {
  // display: grid;
  // grid-column-gap: 1rem;
  // grid-template-areas:
  // 'field-issue-name field-issue-email'
  // 'field-issue-select ...'
  // 'field-issue-details field-issue-details';

  padding: 0 24px;

  .field-issue-name {
    grid-area: field-issue-name;
  }

  .field-issue-email {
    grid-area: field-issue-email;
  }

  .field-issue-select {
    grid-area: field-issue-details;
    width: 50%;
  }

  .field-issue-details {
    grid-area: field-issue-details;
  }
}
