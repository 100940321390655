.panel-footer {
  background-color: #ffffff;
  -webkit-box-shadow: 0px 2px 10px 0px rgba(42, 61, 76, 0.25);
  -moz-box-shadow: 0px 2px 10px 0px rgba(42, 61, 76, 0.25);
  box-shadow: 0px 2px 10px 0px rgba(42, 61, 76, 0.25);
  cursor: default;
  bottom: 0;
  top: auto;

  .contact-form-button-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    .contact-form-button {
      font-family: 'Rubik';
      height: 3rem;
      width: 7rem;
      border-radius: 2px;
      text-transform: capitalize;
    }

    .contact-form-button.button-disabled {
      background-color: $off-body;
      color: $secondary-accent;
      cursor: not-allowed;
    }

    .button-active {
      background-color: $accent;
      color: $white;
    }

    .button-active:hover {
      background-color: darken($accent, 20%);
    }

    .cancel-button {
      color: $icon-color;
    }

    .approve-button {
      background-color: $approve-green;
    }

    .reject-button {
      background-color: $reject-red;
      color: white;
    }
  }
}
