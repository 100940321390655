.event-schedule-container {

  .schedule-container {
    border-radius: 8px;
    background-color: #f4f5f6;
    padding: 16px;

    &:not(:first-child) {
      margin-top: 16px;
    }

    .field-event-schedule-day {
      width: 50%;
    }

  }

  .session-container {

    .session-row {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
      grid-template-rows: auto;
      grid-column-gap: 1rem;
      grid-template-areas:
      'session-name start-date start-date-period end-date end-date-period';
      align-items: end;


      .field-event-session-name {
        grid-area: session-name;
      }

      .field-event-session-start-time {
        grid-area: start-date;
      }

      .field-event-session-start-time-period {
        grid-area: start-date-period;
      }

      .field-event-session-end-time {
        grid-area: end-date;
      }

      .field-event-session-end-time-period {
        grid-area: end-date-period;
        display: flex;
        align-items: baseline;
        position: relative;
      }

      .field-event-session-delete {
        cursor: pointer;
        position: absolute;
        right: -8px;
        top: 13px;
        opacity: 0.5;

        &:hover {
          opacity: 1;
        }
      }

    }

    .link {
      text-align: left;
    }

  }

}
