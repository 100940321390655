.section-checkbox-field-container {
  display: flex;
  align-items: center;

  .section-label {
    font-size: 18px;
    font-weight: 500;
  }
}

.disabled-checkbox {
  opacity: 0.4;
  cursor: not-allowed;
}
