.dashboad-view-container {
  padding: 0px 32px;

  .dashboard-header {
    display: grid;
    grid-template-areas: 'welcome-container welcome-data-container';
    grid-template-columns: 40% 60%;
    // Horizontally
    justify-content: space-between;
    // Vertically
    align-items: center;
    margin-bottom: 32px;
    background: $white;
    border: 1px solid $columbia-grey;
    border-radius: 4px;
    -webkit-box-shadow: 0px 2px 10px 0px rgba(42, 61, 76, 0.14);
    -moz-box-shadow: 0px 2px 10px 0px rgba(42, 61, 76, 0.14);
    box-shadow: 0px 2px 10px 0px rgba(42, 61, 76, 0.14);
    padding: 16px 0px;

    .avatar-big {
      // display: inline-block;
      height: 84px;
      width: 84px;
      font-weight: 500;
      color: $columbia-grey;
      font-size: 32px;
      margin: auto 16px auto 24px;
      background-color: white;
      text-transform: uppercase;
    }

    .welcome-container {
      display: flex;

      .welcome-text-container {
        .small-title {
          margin: 13px 0px 0px 0px;
        }

        .username {
          color: $ocean-blue;
          font-size: 24px;
          font-weight: 100;
          margin: 9px 0px 0px 0px;
          text-transform: capitalize;
        }
      }
    }

    .welcome-data-container {
      display: grid;
      grid-template-areas: 'welcome-data-item welcome-data-item welcome-data-item';
      grid-template-columns: 65% 18% 14%;
      justify-items: right;
      text-align: right;

      .welcome-data-item {
        .data-item-title,
        .data-count-title {
          margin-bottom: 0px;
        }

        .data-item-name-location {
          color: $ocean-blue;
          font-size: 14px;
          margin-top: 8px;
        }

        .data-count-text {
          color: $ocean-blue;
          font-size: 24px;
          margin-top: 16px;
          margin-bottom: 0px;
        }
      }

      button {
        display: inline-block;
        vertical-align: top;
        background-color: #f6f7f8;
        border: 1px solid #ecedee;
        width: 100%;
      }

      .intro-text {
        text-align: right;
      }
    }
  }

  .dashboard-content {
    display: grid;
    grid-template-areas: 'weekly-birthday mukto-and-subscriptions';
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 3%;
    align-items: start;

    .mukto-and-subscriptions {
      display: grid;
      grid-template-rows: auto;
      grid-row-gap: 2rem;
    }
  }
}

@media (max-width: 750px) {
  .dashboad-view-container {
    margin-top: 50px;
    padding: 0px 10%;

    .dashboard-header {
      display: grid;
      grid-template-areas:
        'welcome-container'
        'welcome-data-container';
      grid-template-columns: [only] auto;
      // Vertically
      align-items: center;
      margin-bottom: 50px;

      .avatar-big {
        display: flex;
        height: 84px;
        width: 84px;
      }

      .welcome-container {
        .welcome-text-container {
          display: inline-block;
          vertical-align: bottom;
          margin-left: 20px;
        }
      }

      .welcome-data-container {
        display: grid;
        grid-template-areas:
          'welcome-data-item'
          'welcome-data-item'
          'welcome-data-item';
        grid-template-columns: auto;
        justify-items: right;
        text-align: right;
        margin-right: 16px;

        button {
          display: inline-block;
          vertical-align: top;
          background-color: #f6f7f8;
          border: 1px solid #ecedee;
        }

        .intro-text {
          text-align: right;
        }
      }

      .intro-text {
        color: $turquoise-blue;
      }
    }

    .dashboard-content {
      display: grid;
      grid-template-areas:
        'weekly-birthday'
        'mukto-and-subscriptions'
        'upcoming-events';
      grid-template-columns: auto;
      align-items: start;
      grid-column-gap: 30px;
    }
  }
}
