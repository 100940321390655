
.card {
  background: $white;
  border: 1px solid $columbia-grey;
  border-radius: 4px;
  -webkit-box-shadow: 0px 2px 10px 0px rgba(42,61,76,0.14);
  -moz-box-shadow: 0px 2px 10px 0px rgba(42,61,76,0.14);
  box-shadow: 0px 2px 10px 0px rgba(42,61,76,0.14);

  .card-header {
    padding: 32px 32px 24px 32px !important;
    display: flex;
    justify-content: space-between;

    .card-header-title {
      font-family: 'Rubik';
      font-size: 18px;
      font-weight: 500;
    }

    img {
      cursor: pointer;
    }
  }

  .card-content-container {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch !important;
    padding: 0px 32px 24px 32px !important;
    // height: 422px;
    min-height: 67px;
    max-height: 50vh;
  }

	ul {
		padding: 0px;

		li {
			display: grid;
			grid-template-areas: 'item-label item-label';
			list-style: none;
      margin-bottom: 1rem;

      .item-label {
        text-transform: capitalize;

        .item-name {
          font-weight: 500;
        }

        .item-clickable {
          color: $ocean-blue;
          cursor: pointer;
        }
      }



			.item-location-container {
        // color: $japanese-indigo !important;
        .item-location {
          &:after {
            content: ", "
          }
        }

        .item-state {
          text-transform: uppercase;
        }
			}

			.item-value {
        text-align: right;

        .link {
          font-size: 12px;
        }
      }
		}
  }

  .card-actions {
    justify-content: flex-end;

    .link {
      text-transform: uppercase;
    }
  }

	.link {
		text-align: right;
	}
}
