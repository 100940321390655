@import '../../../styles/utils/palette.scss';
@import './header.scss';
@import './sidebar.scss';

.access-container {
  // width: 100%;
  padding: 32px 32px 64px;
  margin: 0 auto;
  overflow-y: auto;
}

.access-container__separator {
  margin: 32px 0;
  border-bottom: 1px solid $light-grey;
}

.access-container__panel-container {
  display: flex;
  width: 100%;
}

.access-container__panel-content {
  flex: 1 1 100%;
  padding: 0 0 0 34px;
  height: 48vh;
  overflow-y: scroll;
}

.access-container__panel-user-name {
  color: $ocean-blue;
  text-transform: capitalize;
}

.access-container__panel-control-description {
  margin-left: 2rem;
}

.access-container__panel-control {
  margin-top: 32px;
}

.access-container__panel-control {
  & .form-field-text.error-field {
    border-color: $crimson-red;
  }
}

.access-container__field-label {
  display: flex;
  label {
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: $slate-grey;
    margin: 0 0 18px;
  }
}

.access-container__required-symbol {
  color: $crimson-red;
  line-height: 18px;
  margin-right: 4px;
}

.access-container__field-info {
  margin-left: 8px;
}

.access-container__level-selector-container {
  & > .form-field-text-container {
    max-width: 100%;
  }
}

.access-container__panel-permissions-container {
  display: flex;
  text-transform: capitalize;
  margin-left: -1rem;
}

.access-container__panel-control-section-field {
  margin-left: -1rem;
  display: flex;
  align-items: center;

  .section-label {
    font-size: 18px;
    font-weight: 500;
  }

  .access-container__panel-control-checkbox {
    &:hover {
      background-color: transparent;
    }
  }
}

.access-container__modal-style {
  top: 50%;
  left: 50%;
  width: 80vw;
  height: 80vh;
  background: #fff;
  position: fixed;
  transform: translate3d(-50%, -50%, 0);

  .contact-form-dismiss {
    cursor: pointer;
    position: absolute;
    right: 24px;
    top: 24px;
  }
}

// iPhone 6, 7, 8
@media only screen and (min-device-width: 375px) and (max-device-width: 736px) {
  .access-container {
    // width: 100%;
    padding: 16px;
    margin: 0 auto;
    height: 100%;
  }
  .access-container__separator {
    margin: 16px;
  }
  .access-container__panel-control-section-field {
    margin-left: -0.5rem;
  }
  .access-container__panel-control-description {
    margin-left: 2.5rem;
  }
  .access-container__panel-control {
    .level-two-fields-row {
      display: block;
    }
  }
  .access-container__panel-permissions-container {
    display: block;
    margin-left: 0;
    margin-bottom: 32px;
  }
  .access-container__panel-container {
    display: block;
  }
  .access-container__panel-content {
    flex: 1;
    padding: 0 8px;
    height: 48vh;
    margin: 8vw 0;
    overflow-y: scroll;
  }
  .access-container__modal-style {
    width: 90vw;
    height: 90vh;
  }
}
