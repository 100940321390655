.event-panel {
  height: inherit;
  min-height: 100vh;
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: space-between;

  .event-panel-top-row {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    justify-content: space-between;

    .title-text {
      font-size: 24px;
    }

    .event-form-dismiss {
      cursor: pointer;
    }
  }
}
