.contact-card-container {
  background: $white;
  position: relative;
  border-radius: 4px;

  .contact-view-card {
    border: 1px solid $columbia-grey;
    border-radius: 4px;
    -webkit-box-shadow: 0px 2px 10px 0px rgba(42, 61, 76, 0.14);
    -moz-box-shadow: 0px 2px 10px 0px rgba(42, 61, 76, 0.14);
    box-shadow: 0px 2px 10px 0px rgba(42, 61, 76, 0.14);

    .contact-card-city-state {
      text-align: center;
      text-transform: capitalize;
    }

    .contact-card-first-name {
      color: $japanese-indigo;
      text-align: center;
      font-weight: 500;
      text-transform: capitalize;
    }

    .contact-card {
      width: 16rem;
      height: 22rem;

      .checkbox {
        cursor: pointer;
        position: absolute;
        top: 16px;
        left: 16px;
      }

      .checkbox-disabled {
        cursor: not-allowed;
        opacity: 0.5;
        pointer-events: none;
      }
      .contact-card-avatar {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem 0rem 1rem 0;
      }
      .contact-avatar {
        background-color: $white;
        color: $columbia-grey;
        font-weight: 500 !important;
        height: 64px;
        width: 64px;
        text-transform: uppercase;
      }

      .workshop-invitation-status {
        display: none;
      }

      .contact-card-workshop-status {
        display: flex;
        justify-content: center;
        margin-top: 0.7rem;
        text-transform: capitalize;
        color: white;
        cursor: default;

        span {
          font-weight: 400;
          font-size: 13px;
          padding: 0.2rem 0.5rem;
        }

        .contact-card-workshop-invited {
          background-color: $ocean-blue;
          border-radius: 1rem;
        }

        .contact-card-workshop-registered {
          background-color: $tangerine;
          border-radius: 1rem;
        }

        .contact-card-workshop-paid {
          background-color: $lime-green;
          border-radius: 1rem;
        }

        .contact-card-workshop-not-attending {
          background-color: $slate-grey;
          border-radius: 1rem;
        }
      }

      .contact-card-overflow {
        cursor: pointer;
        position: absolute;
        top: 16px;
        right: 6px;
      }

      .contact-card-overflow-list {
        display: none;
      }

      .contact-card-overflow-menu {
        div {
          width: 118px;
          height: 60px;
          border-radius: 2px;
          box-shadow: 0 2px 8px 0 rgba(74, 74, 74, 0.15);
          background-color: #ffffff;
          border: solid 1px #e3e3e4;
        }

        div {
          padding: 6px 14px 7px 10px;

          &:hover {
            background-color: rgba(195, 210, 223, 0.4);
          }
        }
      }

      .contact-card-name {
        color: $japanese-indigo;
        text-align: center;
        font-weight: 500;
        text-transform: capitalize;
      }

      .nickname {
        text-align: center;
        font-size: 12px;
        text-transform: capitalize;
        min-height: 1rem;
      }

      .follow-up,
      .tags,
      .app {
        display: none;
      }

      .contact-details {
        margin-left: 1rem;
        margin-top: 1rem;
      }

      .contact-card-email,
      .contact-card-phoneNumber,
      .contact-card-address {
        display: grid;
        grid-template-areas: 'a b';
        justify-content: flex-start;
        font-size: 12px;
        margin-bottom: 16px;
        margin-right: 16px;

        p,
        div {
          margin: auto 0px auto 8px;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-left: 0;
        }
      }

      .email-icon,
      .phone-icon,
      .address-icon {
        font-size: 15px;
        margin-right: 12px;
        margin-left: 15px;
        margin-top: 2px;
      }

      .contact-card-address-container {
        display: flex;
        align-items: self-start;

        .contact-card-address {
          text-transform: capitalize;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          width: 170px;
          display: -webkit-box;
          overflow: hidden;

          .contact-card-address-state {
            text-transform: uppercase;
          }
        }
      }

      .contact-card-actions {
        display: flex;
        padding: 0px 16px;
        width: 100%;
        position: absolute;
        bottom: 16px;
        justify-content: space-between;

        .stacked-actions-list {
          display: none;
        }

        .contact-card-tag {
          position: relative;
          right: 3px;
        }

        .contact-card-yog-badge {
          background-color: $sunset-orange;
          border-radius: 9px;
          color: $white;
          cursor: default;
          font-family: 'Rubik';
          font-size: 12px;
          font-weight: 500;
          height: 18px;
          line-height: 18px;
          text-align: center;
          text-transform: uppercase;
          width: 46px;
        }

        i {
          cursor: pointer;
          font-size: 15px;
          position: relative;
          bottom: 8px;
        }
      }
    }

    .contact-card-tall {
      height: 24rem;
    }

    .contact-card-opacity {
      filter: blur(24px);
    }

    .contact-card-tags {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;

      display: grid;
      grid-template-areas:
        'title close'
        'tags tags'
        'grid-actions grid-actions';
      grid-template-columns: 87% 13%;
      grid-template-rows: 10% 80% 10%;
      z-index: 2;

      .tag-list {
        margin-top: 16px;
      }

      .contact-card-tags-title {
        font-size: 14px;
        font-weight: 500;
        grid-area: title;
        cursor: default;
        margin: 15px 0px 0px 15px;
        text-transform: uppercase;
      }

      .icon-close {
        cursor: pointer;
        position: relative;
        top: 15px;
      }

      .contact-card-actions {
        grid-area: grid-actions;
        display: grid;
        grid-template-areas: 'a b c';
        grid-template-columns: 13% 13% 74%;
        padding: 0px 15px;

        .hidden {
          visibility: hidden;
        }

        i {
          cursor: pointer;
          font-size: 15px;
          position: relative;
          bottom: 6px;
        }
      }
    }

    .contact-card-tags-hidden {
      display: none;
      z-index: 2;
    }
  }

  .contact-view-list {
    .basic-info,
    .contact-details,
    .follow-up,
    .tags,
    .workshop-invitation-status {
      width: 20%;

      text-transform: capitalize;
      color: white;
      cursor: default;

      .contact-card-workshop-invited {
        background-color: $ocean-blue;
        border-radius: 1rem;
      }

      .contact-card-workshop-registered {
        background-color: $tangerine;
        border-radius: 1rem;
      }

      .contact-card-workshop-paid {
        background-color: $lime-green;
        border-radius: 1rem;
      }

      .contact-card-workshop-not-attending {
        background-color: $slate-grey;
        border-radius: 1rem;
      }
    }

    .assign-follow-up-link {
      color: $ocean-blue;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
        opacity: 50%;
      }
    }

    .assign-follow-up-link-disabled {
      cursor: not-allowed;
      opacity: 50%;
    }

    .nickname,
    .app {
      width: 15%;
    }

    .app {
      display: flex;
      justify-content: center;
    }

    &:hover {
      -webkit-box-shadow: 0px 2px 10px 0px rgba(42, 61, 76, 0.065);
      -moz-box-shadow: 0px 2px 10px 0px rgba(42, 61, 76, 0.065);
      box-shadow: 0px 2px 10px 0px rgba(42, 61, 76, 0.065);
      animation: hoverShadownIn 1s;
    }

    .contact-card-city-state {
      text-transform: capitalize;
      color: $slate-grey;
    }

    .contact-card {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 4.5rem;

      .left-group {
        display: flex;
        align-items: center;
        flex-grow: 1;
        padding-left: 1rem;

        .checkbox {
          cursor: pointer;
          padding-right: 1rem;
        }

        .contact-card-avatar {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 1rem 1rem 1rem 0;

          .contact-avatar {
            background-color: $white;
            color: $columbia-grey;
            font-weight: 500 !important;
            text-transform: uppercase;
          }
        }

        .contact-card-workshop-status {
          display: none;
        }

        .contact-card-overflow {
          display: none;
        }

        .contact-card-overflow-menu {
          div {
            width: 118px;
            height: 60px;
            border-radius: 2px;
            box-shadow: 0 2px 8px 0 rgba(74, 74, 74, 0.15);
            background-color: #ffffff;
            border: solid 1px #e3e3e4;
          }

          div {
            padding: 6px 14px 7px 10px;

            &:hover {
              background-color: rgba(195, 210, 223, 0.4);
            }
          }
        }

        .contact-card-first-name,
        .contact-card-last-name,
        .contact-card-city {
          color: $japanese-indigo;
          text-align: left;
          text-transform: capitalize;
          // width: 7rem;
          margin-right: 2rem;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .contact-card-state {
          text-transform: uppercase;
          width: 3.5rem;
          margin-right: 2rem;
        }

        .nickname {
          text-transform: capitalize;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .contact-details {
          display: flex;
          flex-direction: column;

          .contact-card-email {
            width: 12rem;
            margin-right: 1.5rem;
          }
          .contact-card-phoneNumber {
            width: 7rem;
          }
          .contact-card-address {
            display: none;
          }

          .contact-card-email,
          .contact-card-phoneNumber {
            display: flex;
            align-items: center;
            font-size: 12px;
            margin-right: 1.5rem;
            overflow: hidden;
            text-overflow: ellipsis;

            p {
              margin: auto 0px auto 0px;
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .email-icon,
          .phone-icon,
          .address-icon {
            font-size: 15px;
            display: none;
          }

          .contact-card-address {
            text-transform: capitalize;

            .contact-card-address-state {
              text-transform: uppercase;
            }
          }
        }
      }

      .contact-card-tag {
        display: none;
      }

      .contact-card-actions {
        display: flex;
        align-items: center;

        .contact-card-overflow-list {
          cursor: pointer;
        }

        .card-details-link {
          display: none;
        }

        .stacked-actions-list {
          display: flex;
          flex-direction: column;
          margin-right: 2rem;
        }

        .contact-card-yog-badge {
          display: none;
        }

        .list-details {
          padding-right: 1rem;
        }

        i {
          cursor: pointer;
          font-size: 15px;
          position: relative;
          bottom: 8px;
        }
      }
    }

    .contact-card-opacity {
      filter: blur(24px);
    }

    .contact-card-tags {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      display: none;
      grid-template-areas:
        'title close'
        'tags tags'
        'grid-actions grid-actions';
      grid-template-columns: 87% 13%;
      grid-template-rows: 10% 80% 10%;
      z-index: 2;

      .tag-list {
        margin-top: 16px;
        display: none;
      }

      .contact-card-tags-title {
        font-size: 14px;
        font-weight: 500;
        grid-area: title;
        cursor: default;
        margin: 15px 0px 0px 15px;
        text-transform: uppercase;
        display: none;
      }

      .icon-close {
        cursor: pointer;
        position: relative;
        display: none;
        top: 15px;
      }

      .contact-card-actions {
        grid-area: grid-actions;
        display: grid;
        grid-template-areas: 'a b c';
        grid-template-columns: 13% 13% 74%;
        padding: 0px 15px;

        .hidden {
          visibility: hidden;
        }

        i {
          cursor: pointer;
          font-size: 15px;
          position: relative;
          bottom: 6px;
        }
      }
    }

    .contact-card-tags-hidden {
      display: none;
      z-index: 2;
    }
  }

  .workshop-invitation-status {
    span {
      font-weight: 400;
      font-size: 13px;
      padding: 0.2rem 0.5rem;
    }
  }
}
