.message-list-card-container {
  background: $white;
  border: 1px solid $columbia-grey;
  border-radius: 4px;
  min-height: 80px;

  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  align-items: center;

  .label {
    font-weight: 500;
  }

  .content {
    max-width: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .message-checkbox-container {
    grid-area: message-checkbox-container;

    .checkbox {
      cursor: pointer;
      position: absolute;
      top: 29px;
      left: 16px;
    }
  }

  .message-content-created-scheduled-container {
    display: flex;

    .message-content-container {
      grid-area: message-content;
      width: 15rem;
      white-space: nowrap;
    }

    .message-scheduled-container {
      grid-area: date;
    }

    .message-createdBy-container {
      grid-area: createdBy;
      width: 10rem;
    }
  }

  .status-button-more-container {
    display: flex;
    align-items: center;

    .message-status {
      border-radius: 3px;
      cursor: default;
      display: flex;
      font-size: 12px;
      max-height: 24px;
      min-width: 5rem;
      text-align: center;
      text-transform: lowercase;
      align-items: center;
      justify-content: center;
      padding: 5px 8px;
      margin-right: 1.5rem;

      &.pending {
        background-color: $sunglow-yellow;
        color: $japanese-indigo;
      }

      &.declined {
        background-color: $reject-red;
        color: $white;
      }
      &.cancelled {
        background-color: $reject-red;
        color: $white;
      }

      &.approved {
        color: $white;
        background-color: $approve-green;
      }

      &.expired {
        background-color: $smoke-grey;
        color: $moonstone-grey;
      }

      &.sent {
        background-color: $ocean-blue;
        color: $white;
      }
    }

    .message-button-container {
      justify-self: end;
      grid-area: button;
      margin-right: 1.5rem;

      .message-manage-button {
        border: 3px solid $ocean-blue;
        font-family: 'Rubik', sans-serif;
        border-radius: 3px;
        color: $ocean-blue;
        font-size: 14px;
        text-transform: capitalize;
        max-width: 6rem;
        min-width: 6rem;
        max-height: 55px;
        justify-self: end;

        &:hover {
          background-color: $ocean-blue;
          color: $white;
        }
      }
    }
  }
}

// iPhone 6, 7, 8
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) {
  .message-list-card-container {
    display: block;
    justify-content: normal;
    padding: 1rem;

    .message-content-created-scheduled-container {
      display: block;
    }

    .status-button-more-container {
      margin-top: 1rem;
    }
  }
}

// iPhone 5
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
  .message-list-card-container {
    display: block;
    justify-content: normal;
    padding: 1rem;

    .message-content-created-scheduled-container {
      display: block;
    }

    .status-button-more-container {
      margin-top: 1rem;
    }
  }
}
