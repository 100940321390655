.form-field-text-area {
  font-size: 14px;
  color: $text-body;
  border: 1px solid #c3d2df;
  border-radius: 2px;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  padding: 0.5rem;
  align-content: center;
  min-height: 200px;
  font-family: 'Rubik', sans-serif;

  // &::first-letter {
  //   text-transform: capitalize;
  // }
}
.form-field-text-container {
  margin-bottom: 1rem;
  position: relative;

  .button-disabled {
    filter: saturate(0) opacity(0.6);
    background-color: #e3e3e4;
    cursor: not-allowed;
  }
}
.form-field-error {
  color: $crimson-red;
  text-align: left;
  padding-top: 0.5rem;
  font-size: 12px;
}

.form-radio-group {
  flex-direction: row;
  text-transform: capitalize;
}

.column-radio-group {
  flex-direction: column;
  margin-left: 15px;

  .MuiFormControlLabel-root {
    width: 120px;
  }
}

.form-field-add-new-button {
  color: $water-blue;
  cursor: pointer;
}
.form-radio-button {
  display: flex;
  align-items: center;
}
.form-field-label-row {
  display: flex;
  // justify-content: space-between;
  margin-bottom: 0.5rem;
  // font-weight: 500;

  .form-field-required {
    font-size: 16px;
    color: $sunset-orange;
  }

  .form-field-tooltip-icon {
    width: 11px;
    height: 11px;
    background-color: black;
    color: white;
    border-radius: 100%;
    place-self: center;
    font-size: 10px;
    display: flex;
    justify-content: center;
  }
}
.form-field-multi-text-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}
.form-field-tags-container {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.form-field-button-label {
  color: $text-body;
  display: flex;
  justify-content: space-between;
  width: inherit;
  text-transform: none;
}
