@import '../../../styles/utils/palette.scss';

.access-container__panel-sidebar {
  flex: 1 1 230px;
  border-right: 1px solid $light-grey;
  padding: 0 32px 0 0;
}

.access-container__panel-sidebar-item {
  align-items: center;
  display: flex;
  color: $ocean-blue;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  gap: 8px;
  line-height: 21px;
  margin: 1vw 0;
  padding: 1vw 0.2vw;
  cursor: pointer;
  text-transform: capitalize;

  &:nth-child(1) {
    margin-top: 0;
  }

  & svg {
    height: 22px;
    width: 22px;
  }

  &.active {
    color: $ocean-blue;
    stroke: $ocean-blue;
  }

  &.inactive {
    color: $slate-grey;
    stroke: $slate-grey;
  }

  .dirtyBadge {
    width: 6px;
    height: 6px;
    padding: 0;
    border-radius: 50%;
    background: $approve-green;
    margin-top: 4px;
  }
}

// iPhone 6, 7, 8
@media only screen and (min-device-width: 375px) and (max-device-width: 736px) {
  .access-container__panel-sidebar {
    flex: none;
    padding: 0 16px;
    display: flex;
    overflow-x: scroll;
    align-items: center;
    border-bottom: 1px solid $light-grey;
    border-right: 0;
  }

  .access-container__panel-sidebar-item {
    font-size: 16px;
    line-height: 21px;
    margin: 0vw 4vw 4vw;
    align-items: center;

    &:nth-child(1) {
      margin-left: 0;
    }
  }
}
