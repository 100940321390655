/*
 * margin
 * 1 rem = 16px
 */

.no-margin {
	margin: 0;
}

.margin {
	margin: 2rem;
}

.margin-left {
	margin-left: 2rem;
}

.margin-right {
	margin-right: 2rem;
}

.margin-bottom {
	margin-bottom: 2rem;
}

.margin-md-bottom {
	margin-bottom: 1.5rem;
}
.margin-md-top {
	margin-top: 1.5rem;
}

.margin-top {
	margin-top: 2rem;
}

.margin-sm {
	margin: 1rem;
}

.margin-sm-bottom {
	margin-bottom: 1rem;
}

.margin-sm-top {
	margin-top: 1rem;
}

.margin-sm-left {
	margin-left: 1rem;
}

.margin-sm-right {
	margin-right: 1rem;
}

.margin-xsm-right {
	margin-right: 0.5rem;
}

.margin-xsm-left {
	margin-left: 0.5rem;
}

.margin-xsm-bottom {
	margin-bottom: 0.5rem;
}
