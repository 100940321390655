@import '../../../styles/utils/palette.scss';

.profileBadge-container {
    display: flex;
    align-items: center;
}

.profileBadge-avatar {
    color: $columbia-grey;
    border: 3px solid $columbia-grey;
    font-size: 18px !important;
    height: 44px;
    width: 44px;
    justify-self: center;
    text-transform: uppercase;
    margin-right: 16px;
}

.profileBadge-primaryText {
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    color: $japanese-indigo;
    text-transform: capitalize;
}

.profileBadge-secondaryText {
    font-size: 14px;
    line-height: 20px;
    color: $slate-grey;
    text-transform: capitalize;
}
