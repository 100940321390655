.section-checkbox-field-container-target {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;

  .section-label {
    font-size: 18px;
    font-weight: 500;
  }
}