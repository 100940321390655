.avatar-uploader {

  display: flex;

  .avatar-image {
    background-color: $white;
    border: 3px solid $columbia-grey;
    border-radius: 100%;
    cursor: pointer;
    height: 84px;
    width: 84px;
    margin: 16px auto;
    justify-content: center;
    align-items: center;

    // Avatar text
    color: $columbia-grey;
    font-size: 32px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .avatar-file-input {
    display: none;
  }
}
