.chip {
  margin: 1rem 0.5rem 0 0;
  background-color: white;
  border: 3px solid $ocean-blue;
  border-radius: 3px;
  justify-content: space-between;
  text-transform: capitalize;
  min-width: 75px;
  min-height: 2rem;
  border-radius: 4px;
}

.chip-label {
  font-size: 14px;
  font-weight: 500;
  color: $ocean-blue;
  font-family: 'Rubik', sans-serif;
}

.chip-attributes {
  margin: 0 1rem 1rem 0;
}
