.event-numbers-container {
  display: flex;
  flex-direction: column;

  .event-numbers-row {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
    align-items: flex-start;
  }

  .event-number-and-dismiss {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    grid-column-gap: 1rem;

    .event-number-dismiss {
      margin-top: 0.5rem;
      cursor: pointer;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }

  .event-numbers-add {
    color: $ocean-blue;
    text-transform: capitalize;
    cursor: pointer;
    margin-bottom: 0.5rem;
  }
}