.message-container {
    width: 30rem;
    border-radius: 2px;
    // box-shadow: 0 2px 10px 0 rgba(42, 61, 76, 0.08);
    // background-color: #ffffff;
    // border: solid 1px $off-body;
    display: flex;
    flex-direction: column;
    // padding: 2rem;
    justify-self: center;
    height: fit-content;
    align-items: center;

    .message-image {
        max-width: 10rem;
        max-height: 10rem;
    }

    .message-title {
        font-size: 32px;
        font-weight: 500;
        text-align: center;
        margin-bottom: 1.5rem;
    }

    .message-content {
        font-size: 18px;
        text-align: center;
    }

    .message-submessage {
        font-size: 18px;
        text-align: center;
    }
}
