.broadcast-view-container {
  display: grid;
  grid-template-areas:
    'events-header'
    'events-data-container';
  margin-bottom: 2rem;

  .broadcast-header {
    margin: 0 0 24px 0px;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .broadcast-header-row1 {
      display: flex;
      justify-content: space-between;

      .broadcast-title {
        color: $japanese-indigo;
        margin: auto 0px;
        font-size: 24px;
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    .broadcast-tabs {
      margin-top: 5px;
    }
  }

  .broadcast-data-container {
    padding: 0 4.5rem;

    .message-container-label {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 1rem;
    }

    .inactive-messages-container {
      opacity: 0.5;
    }

    .horizontal-line {
      background-color: $columbia-grey;
      height: 1px;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .broadcast-cards-list-view {
      display: grid;
      grid-row-gap: 0.5rem;
    }
  }
}

// iPhone 6, 7, 8
@media only screen and (min-device-width: 414px) and (max-device-width: 736px) {
  .broadcast-view-container {
    .broadcast-header {
      padding: 0px 2.5rem;
    }

    .broadcast-data-container {
      padding: 0px 2.5rem;
    }
  }
}

// iPhone 5
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
  .broadcast-view-container {
    .broadcast-header {
      padding: 0px 2rem;

      .broadcast-header-row1 {
        .broadcast-title {
          font-size: 18px;
        }
      }
    }

    .broadcast-data-container {
      padding: 0px 1.5rem;
    }
  }
}
