.toaster-container {
  margin-top: 2rem;
  box-shadow: 0 0 12px 0 rgba(42, 61, 76, 0.05);
}

.toaster-message-container {
  display: flex;
  align-items: center;

  .toaster-status {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .status-success {
    background-color: $lime-green;
  }

  .status-error {
    background-color: $crimson-red;
  }
}

.toaster-close-icon {
  cursor: pointer;
  filter: contrast(0);
}
