.content-view-container {
  display: grid;
  grid-template-areas:
    'events-header'
    'events-data-container';
  margin-bottom: 2rem;

  .content-header {
    margin: 0 0 24px 0px;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .content-header-row {
      display: flex;
      justify-content: space-between;

      .content-title {
        color: $japanese-indigo;
        margin: auto 0px;
        font-size: 24px;
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    .content-tabs {
      margin-top: 5px;
    }
  }

  .content-view-data-container {
    padding: 0 5%;

    .content-view-section-all-content {
      .content-view-section-all-content-select-row {
        width: 25%;
      }

      .content-view-section-all-content-data {
      }
    }
  }
}
