@import '../utils/palette.scss';

.avatar-wrapper {
    background-color: $white;
    border: 3px solid $columbia-grey;
    border-radius: 100%;
    height: 64px;
    width: 64px;
    display: flex;
    justify-content: center;
    align-items: center;

    // Avatar text
    color: $columbia-grey;
    font-weight: 500;
    text-transform: uppercase;
  }

  .avatar-wrapper-parent {
    display: flex;
    border: none!important;
  }

  .contact-avatar-icon {
    position: relative;
  }
