.secondary-button-container {
  .secondary-button {
    border: 2px solid $ocean-blue;
    font-family: 'Rubik', sans-serif;
    border-radius: 3px;
    color: $ocean-blue;
    font-size: 14px;
    text-transform: capitalize;
    width: 6rem;
    max-height: 55px;
    justify-self: end;

    &:hover {
      background-color: $ocean-blue;
      border-radius: 3px;
      font-family: 'Rubik', sans-serif;
      color: $white;
      font-size: 14px;
      text-transform: capitalize;
      max-width: 150px;
      max-height: 55px;
      justify-self: end;
    }
  }
}
