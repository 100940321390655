.tag-container {
  background-color: white;
  border: 1px solid $columbia-grey;
  border-radius: 2px;
  cursor: default;
  margin-bottom: 8px;
  margin-left: 15px;
  width: max-content;

  .tag-value {
    color: $japanese-indigo;
    font-size: 14px;
    text-transform: capitalize;
    margin: 0px;
    padding: 8px 16px;
  }
}
