.mdxeditor-field {
  min-height: 200px;
}

div[data-radix-popper-content-wrapper] {
  z-index: 2000 !important;
}

._popupContainer_lug8m_1127 {
  z-index: 2000 !important;
}
//Edit image button was working on read only mode, so disabled it
._editImageButton_lug8m_846 {
  display: none;
}
.field-broadcast-message {
  .form-field-text-container img {
    width: 100%;
    display: block;
  }
}

._focusedImage_lug8m_830 img,
._imageWrapper_lug8m_834 img {
  max-width: 600px;
}
