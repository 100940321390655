.header {
  background-color: $white;
  .header-container {
    display: grid;
    grid-template-areas:
      'utility-row'
      'tabs-row';
    .utility-row {
      padding: 16px 24px;
      grid-area: utility-row;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;
      .onTop {
        z-index: 100;
      }
      .popperCloser {
        pointer-events: 'none';
        transform: translate3d(0, 0, 0) !important;
        transition: transform 10s;
      }
      .header-right-buttons {
        display: flex;
      }
      .header-avatar {
        cursor: pointer;
        width: 28px !important;
        height: 28px !important;
        border-radius: 100%;
        border: 3px solid $ocean-blue;
        color: $ocean-blue;
        text-transform: uppercase;
        background-color: white;
        font-weight: 500;
      }
      .header-logo {
        // cursor: pointer;
        font-family: Rubik;
        color: $reddish-orange;
        font-size: 24px;
      }
      .notification-badge {
        height: 0.5rem;
        width: 0.5rem;
        background-color: $reddish-orange;
        border-radius: 100%;
        position: absolute;
        margin-left: 1.5rem;
      }
    }
    .tabs-row {
      grid-area: tabs-row;
      display: flex;
      justify-content: space-around;
      min-height: 3rem;
      .tab-label {
        color: $japanese-indigo;
        font-weight: 500;
      }
    }
  }
}
@media (min-width: 600px) {
  .header-logo-responsive {
    display: none;
  }
  .responsive-header-menu {
    display: none;
  }
  .hamburger-menu {
    display: none;
  }
}
@media (max-width: 600px) {
  .header-logo-responsive {
    display: flex;
    justify-content: center;
    .header-logo-responsive-img {
      margin-top: 1rem;
      height: 1.5rem;
    }
  }

  .header-user-menu {
    display: none;
  }

  .header-contact-us-menu {
    display: none;
  }

  .responsive-header-menu {
    display: inline;
  }
  .header-report-button {
    display: none;
  }
  .header-notifications-button {
    display: none;
  }

  .header {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    .header-container {
      .utility-row {
        .tabName-desktop {
          display: none;
        }
        justify-content: space-between;
        flex-direction: row;
        padding: 10px 16px;
        .header-logo {
          display: none;
        }
        .header-search-icon-container {
          margin: 0;
          width: 80%;
        }
      }
      .tabs-row {
        display: none;
      }
    }
  }
}
