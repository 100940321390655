.approval-panel {
  height: inherit;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .approval-panel-top-row {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    justify-content: space-between;

    .title-text {
      font-size: 24px;
    }

    .broadcast-form-dismiss {
      cursor: pointer;
    }
  }

  .grey-border-bottom {
    border-bottom: 1px solid $columbia-grey;
    // box-shadow: -2px 0 4px 0 #e7eaed;
  }

  .broadcast-panel-top-section {

    margin-bottom: 1.5rem;

    .broadcast-form-info {
      display: flex;
      align-items: center;
      // width: 60%;
      margin-top: 1rem;
      margin-bottom: 1rem;

      .broadcast-form-avatar {
          background-color: $white;
          color: $columbia-grey;
          border: 3px solid $columbia-grey;
          font-size: 32px !important;
          font-weight: 500 !important;
          height: 84px;
          width: 84px;
          margin-right: 1rem;
          text-transform: uppercase;
      }

      .broadcast-info {
          display: flex;
          flex-direction: column;
          // text-transform: capitalize;
      }
    }
  }

}
.reading-panel-tabs {
  margin-bottom: 1rem;
}
