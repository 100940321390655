.events-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 1rem;
  grid-template-areas:
  'event-custom-targeting event-custom-targeting'
  'event-images event-images'
  'event-type event-type'
  'event-name event-name'
  'event-description event-description'
  'line-1 line-1'
  'title-venue title-venue'
  'venue-name venue-name'
  'address-1 address-2'
  'address-city address-state-zip'
  'line-2 line-2'
  'title-dates title-dates'
  'event-duration event-duration'
  'event-start-date event-end-date'
  'line-3 line-3'
  'event-schedule event-schedule'
  'line-4 line-4'
  'event-contact-numbers event-contact-numbers'
  'line-5 line-5'
  'event-check-in-instructions event-check-in-instructions'
  'line-6 line-6'
  'event-travel-arrangements event-travel-arrangements'
  ;
  max-width: 750px;
  margin:auto;
  margin: 0 auto;

  margin-bottom: 24px;

  .section-label {
    font-size: 18px;
    font-weight: 500;
  }

  .field-event-images {
    grid-area: event-images;
  }

  .field-event-type {
    grid-area: event-type;
  }

  .field-event-name {
    grid-area: event-name;
  }

  .field-event-description {
    grid-area: event-description;
  }

  .field-venue-name {
    grid-area: venue-name;
  }

  .field-address-1 {
    grid-area: address-1;
  }

  .field-address-2 {
    grid-area: address-2;
  }

  .field-address-city {
    grid-area: address-city;
  }

  .field-address-state-zip {
    grid-area: address-state-zip;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-column-gap: 1rem;
  }

  .field-event-duration {
    grid-area: event-duration;
  }

  .field-event-start-date {
    grid-area: event-start-date;
  }

  .field-event-end-date {
    grid-area: event-end-date;
  }

  .subtitle-text {
    font-size: 18px;
  }

  .horizontal-line {
    background-color: $columbia-grey;
    height: 1px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .line-1 {
    grid-area: line-1;
  }

  .line-2 {
    grid-area: line-2;
  }

  .line-3 {
    grid-area: line-3;
  }

  .line-4 {
    grid-area: line-4;
  }

  .line-5 {
    grid-area: line-5;
  }

  .line-6 {
    grid-area: line-6;
  }

  .title-venue {
    grid-area: title-venue;
  }

  .title-dates {
    grid-area: title-dates;
  }

  .field-event-schedule {
    grid-area: event-schedule;
  }

  .field-event-travel-arrangements {
    grid-area: event-travel-arrangements;
  }

  .field-event-custom-targeting {
    grid-area: event-custom-targeting;
      .header-row{
        font-weight: 500;
        font-size: 26px;
    }

  }
  .field-event-check-in-instructions {
    grid-area: event-check-in-instructions;
  }

  .field-event-contact-numbers {
    grid-area: event-contact-numbers;
  }
}
.header-helper{
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */


        /* Gray 70 */

        color: #5F698B;
}