.contact-modal-container {
  .Sampark-MuiDialog-paper {
    width: 1000px;
  }
}

.contact-form {
  height: inherit;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .contact-form-top-elements {
    .padding {
      @media only screen and (max-width: 768px) {
        padding: 1rem;
      }
    }
  }

  .contact-form-top {
    border-bottom: 1px solid $off-body;
    display: grid;
    justify-content: space-between;
    grid-template-rows: auto;
    grid-template-columns: 100%;
    grid-template-areas:
      'contact-form-top-row'
      'contact-form-top-info'
      'contact-form-top-tabs';

    .padding-left {
      @media only screen and (max-width: 768px) {
        padding-left: 1rem;
      }
    }

    .margin-md-top {
      @media only screen and (max-width: 768px) {
        margin-top: 1rem;
      }
    }

    .contact-form-top-row {
      grid-area: contact-form-top-row;
      display: flex;
      justify-content: space-between;

      .contact-form-title {
        display: flex;
        flex-direction: column;

        .contact-header-name {
          display: flex;
          align-items: center;
        }

        .title-text {
          font-size: 20px;
        }

        .contact-form-updated {
          font-size: 12px;
          margin-right: 0.5rem;
        }
      }
      .contact-card-yog-badge {
        background-color: $sunset-orange;
        border-radius: 9px;
        color: $white;
        cursor: default;
        font-family: 'Rubik';
        font-size: 12px;
        font-weight: 500;
        height: 18px;
        line-height: 18px;
        text-align: center;
        text-transform: uppercase;
        width: 46px;
        margin: 0 4px;
      }

      .contact-form-top-row-buttons {
        display: flex;
        align-items: center;
        margin-right: 4px;

        .hover-image-container {
          min-width: 4px;
          margin: 0 10px;
        }

        .contact-form-dismiss {
          cursor: pointer;
          margin-left: 4px;
        }

        .contact-form-share {
          margin-right: 1.5rem;
        }
      }
    }

    .contact-form-content {
      @media only screen and (max-width: 768px) {
        padding: 8px 12px;
      }
    }

    .contact-form-top-tabs {
      grid-area: contact-form-top-tabs;
      justify-content: space-between;
      display: flex;

      .contact-info-tabs {
        width: 100%;
        padding: 0 30px;
        @media only screen and (max-width: 768px) {
          padding: 0 6px;
        }
      }
    }
  }

  input:focus {
    outline: none;
  }

  .contact-form-label {
    padding-bottom: 0.5rem;
  }
}
