.horizontal-line {
  background-color: $columbia-grey;
  height: 1px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.field-broadcast-schedule {

  .field-schedule-option {
    width: 50%;
  }

}

.comments-section {
  padding-top: 1rem;

}