.form-field-text {
  font-size: 14px;
  color: $text-body;
  background-color: $white;
  border: 1px solid #c3d2df;
  border-radius: 2px;
  width: 100%;
  max-width: 100%;
  height: 2.5rem;
  align-content: center;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
}

.form-field-text-character-counter {
  position: absolute;
  right: 0;
  padding-right: 0.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  color: $off-body;
  pointer-events: none;
}

.form-field-textarea-character-counter {
  position: absolute;
  right: 0;
  bottom: 0;
  padding-right: 0.5rem;
  display: flex;
  align-items: center;
  color: $off-body;
  pointer-events: none;
}

.disabled-text-field {
  cursor: not-allowed;
  background-color: $smoke-grey;
  opacity: 0.6;
}
.form-field-text.disabled-text-field:hover {
  border: solid 1px $columbia-grey;
}
.form-field-text:focus {
  border: solid 1px $ocean-blue;
  box-shadow: 0 0 4px 0 rgba(6, 123, 194, 0.5);
}
.form-field-text:hover {
  border: solid 1px $ocean-blue;
}
// ERROR STATE
.form-field-text-error-state {
  border: solid 1px $crimson-red;
  color: $crimson-red;
}
.form-field-text-error-state:hover {
  border: solid 1px $crimson-red;
}
.form-field-text-error-state:focus {
  border: solid 1px $crimson-red;
}
input.form-field-text-error-state::placeholder {
  color: $crimson-red;
  text-transform: none;
}
.form-field-text::placeholder {
  color: $off-body;
  text-transform: none;
  font-family: 'Rubik', sans-serif;
}

.text-field-preview {
  height: 10rem;
  width: 10rem;
  border-radius: 0.5rem;
  background-color: blue;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}
