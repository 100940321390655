.followup-admin-container {
    display: grid;
    grid-template-columns: 40% 60%;
    grid-column-gap: 2%;
    width: 100%
}

.followup-admin-error-container {
    margin: 0rem auto;
    max-width: 650px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.followup-admin-modal-style {
    top: 50%;
    left: 50%;
    width: 25vw;
    min-width: 500px;
    height: 90vh;
    background: #fff;
    position: fixed;
    transform: translate3d(-50%, -50%, 0);
    overflow-y: scroll;
    box-shadow: 0 2px 8px 0 rgba(74, 74, 74, 0.15);

    .contact-form-dismiss {
      cursor: pointer;
      position: absolute;
      right: 24px;
      top: 24px;
    }
}

.followup-admin-link {
    margin-left: 1.5rem
}

// iPhone 6, 7, 8
@media only screen
and (min-device-width : 375px)
and (max-device-width : 736px) {
    .followup-admin-container {
        display: grid;
        grid-template-columns: 100%;
        width: 100%;

        .manage-data-card-container {
            padding: 24px 0px;
            margin: 0;
            background: none;
            box-shadow: none;
            border-radius: 0;
            border: none;
        }
    }

    .followup-admin-modal-style {
        width: 90vw;
        height: 90vw;
    }

}
