body, div, p {
	color: $japanese-indigo;
	font-family: 'Rubik';
	font-weight: 100;
	font-size: 16px;
	line-height: 1.5;
}

h1, h2, h3 {
	font-family: 'Rubik';
	font-weight: bold;
	text-align: left;
	color: $greyish-brown;
	line-height: 1.5;
	margin: 0;
}

h1 {
	font-size: 28px;
}

h2 {
	font-size: 22px;
}

h3 {
	font-size: 20px;
}

body, div, p {
  color: $japanese-indigo;
  font-family: 'Rubik';
  font-weight: 100;
  font-size: 16px;
  line-height: 1.5;
}

.tab-label {
	font-weight: 500;
}

.small-title {
	color: #4a4a4a;
	font-family: 'Rubik';
	font-size: 13px;
	font-weight: 100;
	text-align: left;
}

h4 {
	font-family: 'Rubik';
	font-size: 20px;
	font-weight: bold;
	text-align: left;
	color: $greyish-brown;
	margin: 0;
}

q {
	font-family: Palatino;
	font-size: 24px;
	font-style: italic;
	line-height: 1.42;
	text-align: center;
	color: $greyish-brown;
}

.landing-text {
	font-family: 'Rubik';
	font-size: 24px;
	color: $accent;
}

.intro-text {
	font-family: 'Rubik';
	font-size: 18px;
	line-height: 1.44;
	text-align: left;
	color: $greyish-brown;
};

.form-legend {
	font-family: 'Rubik';
	font-size: 16px;
	font-weight: 500;
	text-align: left;
	color: $greyish-brown;
};

.form-section-label {
	font-family: 'Rubik';
	font-size: 14px;
	font-weight: bold;
	text-align: left;
	color: $greyish-brown;
}

.bold {
	font-weight: 500;
}

.form-button-label {
	font-family: 'Rubik';
	font-size: 14px;
	font-weight: bold;
	text-align: center;
	text-transform: capitalize;
	color: $greyish-brown;
};

.form-button-label-white {
	font-family: 'Rubik';
	font-size: 14px;
	font-weight: 100;
	text-align: center;
	text-transform: capitalize;
	color: white;
};

.form-button-label-disabled {
	font-family: 'Rubik';
	font-size: 14px;
	font-weight: bold;
	letter-spacing: -0.4px;
	text-align: center;
	color: $disabled;
}

.form-input-disabled {
	font-family: 'Rubik';
	font-size: 14px;
	text-align: left;
	color: $disabled;
};

.form-button-label-secondary {
	font-family: 'Rubik';
	font-size: 14px;
	text-align: center;
	color: $greyish-brown;
};

.form-field-add-new-button {
	font-family: 'Rubik';
	font-size: 12px;
	color: $water-blue;
}

.form-input {
	font-family: 'Rubik';
	font-size: 14px;
	text-align: left;
	color: $greyish-brown;
};

.text-body {
	font-family: 'Rubik';
	font-size: 14px;
	line-height: 1.43;
	text-align: left;
	color: $greyish-brown;
};

.form-button-label-secondary-disabled {
	font-family: 'Rubik';
	font-size: 14px;
	text-align: center;
	color: $disabled;
};

.text {
	font-family: 'Rubik';
	font-size: 14px;
	text-align: left;
	color: $greyish-brown;
};

.text-2 {
	font-family: 'Rubik';
	font-size: 28px;
	font-weight: 500;
	text-align: center;
	color: $reddish-orange;
};

.text-small {
	font-family: 'Rubik';
	font-size: 10px;
	line-height: 1.5;
	text-align: left;
	color: $greyish-brown;
}

.text-small-disable {
  font-family: 'Rubik';
  font-size: 10px;
  line-height: 1.5;
  text-align: left;
  color: $greyish-brown;
  opacity: 0.5;
}

.link, .link:active, .link:focus, .link:hover {
	font-family: 'Rubik';
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: right;
	color: $ocean-blue;
	cursor: pointer;
	outline: none;
	text-decoration: none;
}

.uppercase {
	text-transform: uppercase;
}

.capitalize {
	text-transform: capitalize;
}
