.image-uploader-container {

  .image-uploader-box {
    position: relative;

    .image {
      cursor: pointer;
    }

    .image-max {
      height: 64px;
      width: auto;
      border: 1px solid $columbia-grey;
      border-radius: 3px;
    }

    .image-icon-delete {
      cursor: pointer;
    }
  }

  .image-file-input {
    display: none;
  }

  .image-icon-delete {
    position: absolute;
    right: -5px;
    top: -5px;
  }
}
