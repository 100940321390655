.contact-form-top-info {
  grid-area: contact-form-top-info;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  grid-template-rows: auto;
  grid-template-areas:
    'profile-image first-name last-name'
    'profile-image nick-name nick-name';

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'profile-image profile-image'
      'first-name last-name'
      'nick-name nick-name';
  }

  .contact-form-profile-image {
    grid-area: profile-image;
    justify-self: left;
    position: relative;
    bottom: 10px;
  }

  .field-first-name {
    grid-area: first-name;
  }

  .field-last-name {
    grid-area: last-name;
  }

  .field-nickname {
    grid-area: nick-name;
  }

  @media only screen and (max-width: 768px) {
    .field-last-name,
    .field-nickname {
      padding-right: 0rem;
    }
  }
}
