.loading-mask {
  // background-image: url('../../assets/icons/icon_spinner.gif');
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  pointer-events: none;
}
