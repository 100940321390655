@import '../utils/palette.scss';

.signin-layout {
  height: 100vh;

    .sampark-logo {
      color: $reddish-orange !important;
      font-weight: 500;
      font-size: 28px;
      padding: 2rem 0 0 2rem;
      img {
        cursor: pointer;
      }
    }

    .sampark-footer {
      color: $greyish-brown;
      font-size: 14px;
      padding: 0.5rem;
    }

    .signin-container {
      height: 100vh;
    }

    .signup-message {
      text-align: left;
      background-color: $duck-egg-blue;
      border: 2px solid $water-blue;
      border-radius: 2px;
      color: $greyish-brown;
      padding: 1rem;
      margin: 2rem 4rem 1rem 4rem;
    }
}
