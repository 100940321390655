.button-primary {
  height: 50px;
  min-width: 147px;
  border-radius: 3px;
}

.button-primary-disabled {
  background-color: $columbia-grey;
  opacity: 0.5;
  cursor: not-allowed;
}

.button-orange {
  color: whitesmoke;
  background-color: $sunset-orange;
}

.button-orange:hover {
  background-color: darken($sunset-orange, 20%);
}

.button-secondary {
  cursor: pointer;
  height: 50px;
  min-width: 147px;
  border-radius: 3px;
  border: solid 2px $ocean-blue;
  font-family: 'Rubik';
}

.button-small {
  font-size: 14px;
  min-width: 106px;
  height: 40px;
}

.button-secondary:hover {
  color: white;
  background-color: $ocean-blue;
}

.button-secondary-disabled {
  opacity: 0.4;
  color: $ocean-blue;
  border: solid 2px $ocean-blue;
}

.button-primary-label {
  font-size: 18px;
  font-family: 'Rubik', sans-serif;
  text-transform: capitalize;
  font-weight: 400;
}
.label-white {
  color: white;
}

.button-green {
  background-color: $approve-green;
  color: white;
}
.button-green:hover {
  background-color: darken($approve-green, 9%);
  color: white;
}

.button-red {
  background-color: $reject-red;
  color: white;
}
.button-red:hover {
  background-color: darken($reject-red, 9%);
  color: white;
}
