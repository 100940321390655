.follow-up-suggestion-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .follow-up-avatar {
    height: 28px;
    width: 28px;
    margin-right: 0.5rem;
    border: 3px solid $icon-color;
    text-transform: uppercase;
  }
}