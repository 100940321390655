.personal-info-section {
  display: block;

  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
    grid-template-areas:
      'birthday gender'
      'email email'
      'phone-cell phone-home'
      'field-address-1 field-address-2'
      'field-address-city field-address-state'
      'field-address-zip marriage'
      'mandal mandal'
      'mukt-type mukt-type'
      'attributes attributes'
      'admin-attributes admin-attributes'
      'audience-display audience-display'
      'follow-up .'
      'notes notes'
      'interests interests';
  }

  .field-reference {
    display: flex;
    align-items: center;
    background-color: #f4f5f6;
    border-radius: 0.5rem;
    width: fit-content;
    margin-bottom: 0.5rem;
  }

  .field-birthday {
    grid-area: birthday;
  }
  .field-gender {
    grid-area: gender;
    align-self: center;
  }
  .field-marriage {
    grid-area: marriage;
  }
  .field-mandal {
    grid-area: mandal;
  }
  .field-mukt-type {
    grid-area: mukt-type;
  }
  .field-attributes-selector {
    grid-area: attributes;
  }
  .field-admin-attributes-selector {
    grid-area: admin-attributes;
  }
  .field-email {
    grid-area: email;
  }
  .field-phone-number {
    grid-area: phone;
  }
  .field-phone-cell {
    grid-area: phone-cell;
  }
  .field-phone-home {
    grid-area: phone-home;
  }
  .field-address-1 {
    grid-area: field-address-1;
  }
  .field-address-2 {
    grid-area: field-address-2;
  }
  .field-address-city {
    grid-area: field-address-city;
  }
  .field-address-state {
    grid-area: field-address-state;
  }
  .field-address-zip {
    grid-area: field-address-zip;
  }
  .field-audiences-display {
    grid-area: audience-display;
    padding: 1rem 0;
  }
  .field-notes {
    grid-area: notes;
  }
  .field-follow-up {
    grid-area: follow-up;
  }
  .field-interests {
    grid-area: interests;
  }
}
