.thakorji-smruties-view-container {
  .thakorji-smruties-header {
    margin: 0 0 24px 0px;
    padding: 0 24px;

    .thakorji-smruties-title {
      color: $japanese-indigo;
      margin: auto 0px;
      font-size: 24px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  .thakorji-smruties-content {
    margin: 32px 0px;
    padding: 0 4.5rem;

    .radio-button-container {
      display: flex;

      .radio-button {
        margin-right: 5px;
      }
    }

    .images-display-container {
      display: flex;

      img {
        margin-right: 10px;
        max-width: 200px;
        max-height: 100px;
      }
    }
  }

  .upload-image-button {
    margin-top: 20px;
  }
  .upload-image-button[disabled] {
    background-color: $columbia-grey;
  }
}
