.checkbox-field-list {
  min-width: 10rem;
  max-height: 34.5rem;

  .checkbox-header-search {
    background-color: white;
  }

  .checkbox-header-select-all {
    padding-left: 1rem;
    color: $text-body;
    font-size: 14px;
    font-weight: bold;
  }

  .checkbox-menu-item {
    font-size: 14px;

    &:hover {
      cursor: pointer;
      background-color: darken(#fff, 10%);
    }
  }
}
