.admin-view-container {
  display: grid;
  grid-template-areas:
    'admin-header'
    'admin-data-container';

  .admin-header {
    margin: 0 0 24px 0px;
    padding: 0 24px;

    .admin-title {
      color: $japanese-indigo;
      margin: auto 0px;
      font-size: 24px;
      font-weight: 500;
      text-transform: uppercase;
    }

    .admin-tabs {
      margin-top: 5px;
    }
  }

  .admin-data-container {
    padding: 0 7rem;
    background-color: white;

    .admin-data-approvals-container {
      .admin-data-approval-section {
        margin-bottom: 2rem;
      }
    }
  }
}

// iPhone 6, 7, 8
@media only screen and (min-width: 375px) and (max-width: 736px) {
  .admin-view-container {
    display: block;

    .admin-header {
      margin: 0px;
      padding: 0 1rem;

      .admin-title {
        font-size: 1.5em;
      }

      .admin-tabs {
        margin-top: 5px;
        .admin-tab {
          display: block;
          font-size: 11px;
        }
      }
    }

    .admin-data-container {
      // padding: 0 0.5rem;
      padding: 0;

      .admin-data-user-access-container {
        display: block;
      }
    }
  }
}
