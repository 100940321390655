.notifications-view-container {
    display: grid;
    grid-template-areas:
    'notifications-view-header'
    'notifications-data-container';

    .notifications-view-header {
        margin: 32px 0px;
        padding: 0 7rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .notification-view-title {
            color: $japanese-indigo;
            margin: auto 0px;
            font-size: 24px;
            font-weight: 500;
            text-transform: uppercase;
            padding-bottom: 2rem;
        }

        .notifications-select-container {
            width: 15rem;
        }
    }

    .notifications-data-container {
        padding: 0px 7rem;
        // height: 685px;
        // overflow-y: scroll;
    }

}