.table-header {
  font-family: 'Rubik', 'sans-serif';

  th {
    font-weight: 500;
  }
}

.yog-report {
  padding-top: 24px;
  max-width: 1400px;
  width: 90%;
  text-align: left;
  margin: 0 auto;

  .top-controls {
    text-align: right;
    margin: 10px 0 10px 10px;
    .total-count {
      margin: 0 8px;
      font-size: 16px;
      font-weight: 500;
    }
    button {
      background: $moonstone-grey;
      color: #fff;
      border: 0;
      border-radius: 8px;
      padding: 8px 16px;
      font-weight: 500;
      font-size: 16px;
    }
  }

  table {
    border-spacing: 0px;
    border-collapse: collapse;
    tr {
      &:nth-child(even) {
        background: $columbia-grey;
      }
      &:nth-child(odd) {
        background: $nickel;
      }
    }
    thead {
      background: $moonstone-grey;
      color: $white;
      tr {
        background: $moonstone-grey !important;
      }
    }
    th,
    td {
      padding: 8px;
      background-color: none;
      border: 1px solid #50748f;
    }
    th {
      .sort-button {
        background: transparent;
        color: #ffffff;
        border: 0;
        border-radius: 8px;
        font-weight: 500;
        font-size: 16px;
        padding: 2px 4px;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  .pagination-controls {
    display: flex;
    justify-content: center;
    margin: 10px auto;

    button {
      background: transparent;
      color: $moonstone-grey;
      border: 0;
      border-radius: 8px;
      padding: 8px 16px;
      font-weight: 500;
      font-size: 16px;
      &:disabled {
        color: $disabled;
      }
    }

    .current-page-no {
      padding: 8px;
      color: $moonstone-grey;
      font-weight: 500;
      font-size: 16px;
    }
  }
  .positive-color {
    color: $approve-green;
  }
  .negative-color {
    color: $crimson-red;
  }
}
